import { useState, useEffect, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import LoadingSpinner from "../../../icons/LoadingSpinner";
import { ManageColumns } from "../../../utils/tableFunctions";
import Checkbox from "../../Checkbox";
import { useFetchFunctions } from "../../../services/trips";
import toast from "react-hot-toast";
import ModifyTrip from "./ModifyTrip";
import RerouteTrip from "./RerouteTrip";
import { TripContext } from "../../../utils/Contexts";
import TableRow from "./TableRow";
import { COLUMNS } from "./constants";
import { defineDynamicColumns } from "./utils";
import TripOccurrenceFlyout from "../trip-occurrences/TripOccurrenceFlyout";

export default function TripLocationsTable({
  isLoading,
  trip,
  operator_short_name,
  service_date,
  operatorTripLocationFields,
}) {
  const [visibleTableColumns, setVisibleTableColumns] = useState(COLUMNS);
  const [showNonStation, setShowNonStation] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [locationsToBeEdited, setLocationsToBeEdited] = useState({});
  const [updatingTripLocations, setUpdatingTripLocations] = useState(false);

  const [occurrenceVia, setOccurrenceVia] = useState("");
  const [occurrenceDrawer, setOccurrenceDrawer] = useState(false);

  const [modifyTripDrawerIsOpen, setModifyTripDrawerIsOpen] = useState(false);
  const [rerouteTripDrawerIsOpen, setRerouteTripDrawerIsOpen] = useState(false);

  const { currentTripContext, setCurrentTripContext } = useContext(TripContext);

  const {
    editTripLocations,
    originateTripLocation,
    terminateTripLocation,
    fetchPaths,
  } = useFetchFunctions();

  const {
    isLoading: checkingIfIsEligibleForReroute,
    isError,
    data: possibleRoutes,
    error,
  } = useQuery({
    queryKey: [
      "is_eligible_for_reroute",
      operator_short_name,
      service_date,
      trip?.trip_name,
    ],
    queryFn: () =>
      fetchPaths(
        operator_short_name,
        trip?.trip_locations[0].location?.code,
        trip?.trip_locations[trip?.trip_locations.length - 1].location?.code
      ),
    staleTime: 1000 * 10,
    enabled: !!trip && !isLoading,
  });

  useEffect(() => {
    if (trip && !isLoading) {
      const newColumns = defineDynamicColumns(
        editMode,
        trip?.trip_locations,
        operatorTripLocationFields,
        visibleTableColumns
      );
      setVisibleTableColumns([...COLUMNS, ...newColumns]);
    }
  }, [trip, isLoading, operatorTripLocationFields, editMode]);

  const handleSaveTripLocationsTable = async () => {
    const locationsToBeEditedJson = JSON.parse(JSON.stringify(locationsToBeEdited));
    const formattedPayload = [];

    for (const locationId in locationsToBeEditedJson) {
      const location = {};
      location.id = parseInt(locationId);

      location.values = [];
      for (const value in locationsToBeEditedJson[locationId]?.values) {
        location.values.push({
          field: value,
          value: locationsToBeEditedJson[locationId]?.values[value]?.value,
        });
      }

      const actualIsStoppingValue =
        locationsToBeEditedJson[locationId]?.actual_is_stopping?.value;

      if (actualIsStoppingValue !== undefined) {
        location.actual_is_stopping = actualIsStoppingValue;
      }

      formattedPayload.push(location);
    }

    setUpdatingTripLocations(true);
    try {
      await editTripLocations(
        operator_short_name,
        service_date,
        trip.trip_name,
        formattedPayload
      ).then((res) => {
        if (!res.error) {
          setEditMode(false);
          trip.trip_locations = res.trip_locations;
          toast.success(`Trip ${trip?.trip_name} saved`);
          setCurrentTripContext(res);
          setLocationsToBeEdited({});
        }
        setUpdatingTripLocations(false);
      });
    } catch (error) {
      console.log("Error:", error);
      setUpdatingTripLocations(false);
    }
  };

  const handleOriginateTripLocation = async (trip_location_id) => {
    const formattedPayload = {
      trip_location_id: parseInt(trip_location_id),
    };

    try {
      await originateTripLocation(
        operator_short_name,
        service_date,
        trip.trip_name,
        formattedPayload
      ).then((res) => {
        if (!res.error) {
          trip.trip_locations = res.trip_locations;
          toast.success(`Trip ${trip?.trip_name} saved`);
          setCurrentTripContext(res);
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleTerminateTripLocation = async (trip_location_id) => {
    const formattedPayload = {
      trip_location_id: parseInt(trip_location_id),
    };

    try {
      await terminateTripLocation(
        operator_short_name,
        service_date,
        trip.trip_name,
        formattedPayload
      ).then((res) => {
        if (!res.error) {
          trip.trip_locations = res.trip_locations;
          toast.success(`Trip ${trip?.trip_name} saved`);
          setCurrentTripContext(res);
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <div>
      <div className="drawer-content">
        <div className="card card-compact bg-base-100 border border-base-300 prose">
          <div className="card-header py-2 px-4 border-b">
            <div className="flex justify-between">
              <h3 className="m-0 truncate">Trip Locations</h3>
              <div className="flex gap-2 items-center">
                {!editMode ? (
                  <>
                    <div className="hidden md:block form-control">
                      <label className="label">
                        <Checkbox
                          checked={showNonStation}
                          value={showNonStation}
                          handleOnChange={(event) => setShowNonStation(event)}
                        />
                        <span className="label-text font-bold ml-2 truncate">
                          Show non-stations
                        </span>
                      </label>
                    </div>
                    <button
                      className="btn btn-sm btn-primary  hidden md:inline-block"
                      onClick={() => setEditMode(true)}
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => setModifyTripDrawerIsOpen(true)}
                      className="btn btn-sm btn-primary  hidden md:inline-block"
                    >
                      Extend
                    </button>
                    <button
                      className="btn btn-sm btn-primary hidden md:inline-block"
                      onClick={() => setRerouteTripDrawerIsOpen(true)}
                      disabled={
                        checkingIfIsEligibleForReroute || possibleRoutes?.length <= 1
                      }
                    >
                      {checkingIfIsEligibleForReroute ? (
                        <LoadingSpinner />
                      ) : (
                        "Reroute"
                      )}
                    </button>
                    {/* <ManageColumns
                      columns={visibleTableColumns}
                      setVisibleTableColumns={setVisibleTableColumns}
                    /> */}
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => handleSaveTripLocationsTable()}
                      disabled={
                        Object.keys(locationsToBeEdited)?.length === 0 ||
                        updatingTripLocations
                      }
                    >
                      Save
                      {updatingTripLocations && <LoadingSpinner />}
                    </button>
                    <button
                      onClick={() => {
                        setEditMode(false);
                        setLocationsToBeEdited({});
                      }}
                      className="btn btn-sm btn-primary"
                    >
                      Cancel
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="max-h-96 overflow-auto">
            <table className="trips-table table m-0 lg:text-base table-pin-rows">
              <thead>
                <tr className="z-20">
                  <th className="pr-0 md:sticky md:left-0 md:top-0 md:bg-white z-20"></th>
                  {visibleTableColumns.map((column, id) => {
                    if (column.visible) {
                      return <th className={column.className ?? ""} key={id}>{column.display_name}</th>;
                    }
                  })}
                  {!editMode && <th className="w-full"></th>}
                </tr>
              </thead>
              <tbody>
                {trip?.trip_locations?.length < 1 && <div>No locations found</div>}
                {trip?.trip_locations
                  ?.filter(
                    (trip_location) =>
                      showNonStation || trip_location?.location?.allow_stops === true
                  )
                  .map((trip_location, index) => {
                    return (
                      <TableRow
                        trip={trip}
                        trip_location={trip_location}
                        key={index}
                        index={index}
                        visibleTableColumns={visibleTableColumns}
                        editMode={editMode}
                        locationsToBeEdited={locationsToBeEdited}
                        setLocationsToBeEdited={setLocationsToBeEdited}
                        operatorTripLocationFields={operatorTripLocationFields}
                        setOccurrenceDrawer={setOccurrenceDrawer}
                        setOccurrenceVia={setOccurrenceVia}
                      />
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="card-footer py-2 px-4 bg-base-300 rounded-b">
            <span className="font-bold">Total Mileage</span>{" "}
            {isLoading && <LoadingSpinner />}
            {trip?.actual_mileage?.toFixed(2)}
          </div>
        </div>
      </div>

      <div className="drawer drawer-end">
        <input
          id="add-occurrence-drawer"
          checked={occurrenceDrawer}
          type="checkbox"
          className="drawer-toggle"
          onChange={() => { }}
        />
        <TripOccurrenceFlyout
          operator_short_name={operator_short_name}
          service_date={service_date}
          trip={trip}
          occurrenceDrawer={occurrenceDrawer}
          setOccurrenceDrawer={setOccurrenceDrawer}
          occurrenceVia={occurrenceVia}
          setOccurrenceVia={setOccurrenceVia}
          handleOriginateTripLocation={handleOriginateTripLocation}
          handleTerminateTripLocation={handleTerminateTripLocation}
        />
      </div>


      <div className="drawer drawer-end">
        <input
          id="modify-trip-drawer"
          checked={modifyTripDrawerIsOpen}
          type="checkbox"
          className="drawer-toggle"
          onChange={() => { }}
        />
        <ModifyTrip
          modifyTripDrawerIsOpen={modifyTripDrawerIsOpen}
          setModifyTripDrawerIsOpen={setModifyTripDrawerIsOpen}
          trip={trip}
          operator_short_name={operator_short_name}
          service_date={service_date}
          trip_name={trip?.trip_name}
          tripIsLoading={isLoading}
        />
      </div>

      <div className="drawer drawer-end">
        <input
          id="reroute-trip-drawer"
          checked={rerouteTripDrawerIsOpen}
          type="checkbox"
          className="drawer-toggle"
          onChange={() => { }}
        />
        <RerouteTrip
          rerouteTripDrawerIsOpen={rerouteTripDrawerIsOpen}
          setRerouteTripDrawerIsOpen={setRerouteTripDrawerIsOpen}
          trip={trip}
          operator_short_name={operator_short_name}
          service_date={service_date}
        />
      </div>
    </div >
  );
}
