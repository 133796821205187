import Checkbox from "./Checkbox";

export default function SignedOffCheckboxes({ handleOnChange, name, value }) {
    const yesBox = value === "TRUE" || value === "";
    const noBox = value === "FALSE" || value === "";

    const handleYesChange = () => {
        let newValue;
        if (yesBox && noBox) {
            newValue = "FALSE";
        } else if (yesBox && !noBox) {
            newValue = undefined;
        } else if (!yesBox && noBox) {
            newValue = "";
        } else {
            newValue = "TRUE";
        }
        handleOnChange(newValue);
    };

    const handleNoChange = () => {
        let newValue;
        if (yesBox && noBox) {
            newValue = "TRUE";
        } else if (!yesBox && noBox) {
            newValue = undefined;
        } else if (yesBox && !noBox) {
            newValue = "";
        } else {
            newValue = "FALSE";
        }
        handleOnChange(newValue);
    };

    return (
        <div className="flex gap-4 items-center">
            <div className="w-32">
                <label className="font-bold">{name}</label>
            </div>

            <div className="flex gap-4">
                <div className="form-control">
                    <label className="label gap-2 px-0 cursor-pointer">
                        <span className="label-text">Yes</span>
                        <Checkbox
                            toggle={false}
                            checked={yesBox}
                            handleOnChange={handleYesChange}
                        />
                    </label>
                </div>

                <div className="form-control">
                    <label className="label gap-2 px-0 cursor-pointer">
                        <span className="label-text">No</span>
                        <Checkbox
                            toggle={false}
                            checked={noBox}
                            handleOnChange={handleNoChange}
                        />
                    </label>
                </div>
            </div>
        </div>
    );
}
