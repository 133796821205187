import YesNoCheckbox from "../YesNoCheckbox";
import MultiSelect from "../MultiSelect";
import TextField from "../TextField";
import { useState } from "react";

export default function AdvancedSearchFilters({
  handleNewTripValue,
  searchPayload,
  operatorTripFields,
}) {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 items-center">
          {operatorTripFields?.data
            ?.filter((tripField) => tripField["trip_field"]["type"] === "BOOLEAN" && tripField.featured)
            .map((tripField) => (
              <div key={tripField["id"]}>
                <YesNoCheckbox
                  key={tripField["id"]}
                  name={tripField["trip_field"]["label"]}
                  handleOnChange={(newValue: boolean) =>
                    handleNewTripValue(tripField["trip_field"]["name"], newValue)
                  }
                  value={
                    searchPayload?.trip_values?.find(
                      (trip_value) =>
                        trip_value.field_name === tripField["trip_field"]["name"]
                    )?.value
                  }
                />
              </div>
            ))}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 items-center">
          {operatorTripFields?.data
            ?.filter((tripField) => tripField["lookup_list_items"]?.length && tripField.featured)
            .map((tripField) => (
              <div key={tripField["id"]}>
                <div className="flex gap-4 items-center">
                  <div className="w-32">
                    <label className="font-bold">
                      {tripField["trip_field"]["label"]}
                    </label>
                  </div>
                  <div className="w-60">
                    <MultiSelect
                      className={"w-full"}
                      handleOnChange={(event) =>
                        handleNewTripValue(tripField["trip_field"]["name"], event)
                      }
                      value={
                        searchPayload?.trip_values?.find(
                          (trip_value) =>
                            trip_value.field_name ===
                            tripField["trip_field"]["name"]
                        )?.value
                      }
                      options={tripField["lookup_list_items"].map((item) => {
                        return { value: item, label: item };
                      })}
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className={`collapse collapse-arrow ${isChecked ? "!overflow-visible" : ""}`}>
        <input
          type="checkbox"
          className="min-h-0"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <div className="collapse-title py-3 px-0 link text-center text-primary min-h-0 sm:hidden">
          {isChecked ? "Hide" : "Show"} Advanced Filters
        </div>

        <h3 className="m-0 leading-7 px-0 pb-0 hidden collapse-title sm:block">Advanced Filters</h3>
        <div className="collapse-content flex flex-col gap-4 p-0 sm:visible">
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 items-center">
              {operatorTripFields?.data
                ?.filter((tripField) => tripField["trip_field"]["type"] === "BOOLEAN" && !tripField.featured)
                .map((tripField) => (
                  <div key={tripField["id"]}>
                    <YesNoCheckbox
                      key={tripField["id"]}
                      name={tripField["trip_field"]["label"]}
                      handleOnChange={(newValue: boolean) =>
                        handleNewTripValue(tripField["trip_field"]["name"], newValue)
                      }
                      value={
                        searchPayload?.trip_values?.find(
                          (trip_value) =>
                            trip_value.field_name === tripField["trip_field"]["name"]
                        )?.value
                      }
                    />
                  </div>
                ))}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 items-center">
              {operatorTripFields?.data
                ?.filter((tripField) => tripField["lookup_list_items"]?.length && !tripField.featured)
                .map((tripField) => (
                  <div key={tripField["id"]}>
                    <div className="flex gap-4 items-center">
                      <div className="w-32">
                        <label className="font-bold">
                          {tripField["trip_field"]["label"]}
                        </label>
                      </div>
                      <div className="w-60">
                        <MultiSelect
                          className={"w-full"}
                          handleOnChange={(event) =>
                            handleNewTripValue(tripField["trip_field"]["name"], event)
                          }
                          value={
                            searchPayload?.trip_values?.find(
                              (trip_value) =>
                                trip_value.field_name ===
                                tripField["trip_field"]["name"]
                            )?.value
                          }
                          options={tripField["lookup_list_items"].map((item) => {
                            return { value: item, label: item };
                          })}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
