import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListTree } from "@fortawesome/pro-regular-svg-icons";
import SingleSelect from "../../../SingleSelect";
import NumberField from "../../../NumberField";
import LinkedTripOccurrenceCard from "../LinkedTripOccurrenceCard";
import DynamicFormFields from "../DynamicFormFields";
import WaiverStatusForm from "../WaiverStatusForm";
import TextArea from "../../../TextArea";

export default function LinkToExistingForm({
  //form type props
  occurrenceVia,
  //data props
  operatorTripOccurrenceFields,
  occurrencesOperatorServiceDay,
  //form states
  linkedTripOccurrence,
  operatorTripOccurrenceFieldsPayload,
  //form setters
  handleFieldChange,
  setOperatorTripOccurrenceFieldsPayload,
  handleSetOperatorTripOccurrenceFieldValues,
  setLinkedTripOccurrence,
  //for edit mode
  isEditMode,
  originalValues,
}) {
  const [showDelayMinutes, setShowDelayMinutes] = useState(false);

  useEffect(() => {
    if (
      occurrenceVia?.action === "originate" ||
      occurrenceVia?.action === "terminate" ||
      occurrenceVia?.action === "cancel"
    ) {
      setShowDelayMinutes(false);
    } else {
      setShowDelayMinutes(true);
    }
  }, [occurrenceVia]);

  return (
    <div>
      <div>
        <label className="label">
          <span className="label-text">
            {!linkedTripOccurrence.parent_occurrence ||
              !linkedTripOccurrence.parent_trip_occurrence ? (
              <span>
                Link this to<span className="text-error"> *</span>
              </span>
            ) : (
              <div>
                <FontAwesomeIcon icon={faListTree} size="lg" /> Primary Occurrence
              </div>
            )}
          </span>
          {linkedTripOccurrence.parent_occurrence &&
            linkedTripOccurrence.parent_occurrence !== "" && (
              <button
                className="btn btn-sm btn-outline btn-error"
                onClick={() => handleFieldChange("parent_occurrence", "", "linked")}
              >
                UNLINK
              </button>
            )}
          {linkedTripOccurrence.parent_trip_occurrence &&
            linkedTripOccurrence.parent_trip_occurrence !== "" && (
              <button
                className="btn btn-sm btn-outline btn-error"
                onClick={() =>
                  handleFieldChange("parent_trip_occurrence", "", "linked")
                }
              >
                UNLINK
              </button>
            )}
        </label>
        {!linkedTripOccurrence.parent_occurrence &&
          !linkedTripOccurrence.parent_trip_occurrence ? (
          <SingleSelect
            isLoading={occurrencesOperatorServiceDay.isLoading}
            isDisabled={occurrencesOperatorServiceDay.isLoading}
            useFormatGroupLabel={true}
            linkedOccurrenceDropdown={true}
            placeholder="Select Occurrence"
            value={linkedTripOccurrence.parent_occurrence}
            handleOnChange={(parent_occurrence) =>
              handleFieldChange(
                "parent_trip_occurrence",
                parent_occurrence,
                "linked"
              )
            }
            options={
              Array.isArray(occurrencesOperatorServiceDay?.data) &&
              occurrencesOperatorServiceDay?.data?.map((occurrence) => {
                let options = [];

                options = occurrence?.linked_trip_occurrences?.map(
                  (linked_trip_occurrence) => {
                    return {
                      value: linked_trip_occurrence.trip_occurrence_id,
                      label: linked_trip_occurrence.description,
                      long_description: linked_trip_occurrence?.long_description,
                    };
                  }
                );

                return {
                  onClick: (occurrence) => {
                    if (occurrence.selectable === 1) {
                      handleFieldChange("parent_occurrence", occurrence, "linked");
                    }
                  },
                  value: occurrence,
                  label: occurrence.name,
                  options: options?.length ? options : [""],
                };
              })
            }
          />
        ) : (
          <LinkedTripOccurrenceCard
            linkedTripOccurrence={linkedTripOccurrence}
            isEditMode={isEditMode}
          />
        )}
      </div>

      {showDelayMinutes && (
        <div className="form-control max-w-20">
          <label className="label">
            <span className="label-text">
              Delay Minutes <span className="text-error">*</span>
            </span>
          </label>
          <NumberField
            value={linkedTripOccurrence.delay_minutes}
            handleOnChange={(event) => {
              if (event.target.value < 0) {
                return
              } else {
                handleFieldChange(
                  "delay_minutes",
                  Number(event.target.value),
                  "linked"
                )
              }
            }
            }
          />
        </div>
      )}

      {/* dynamic operator trip occurrence fields */}
      <DynamicFormFields
        fields={operatorTripOccurrenceFields?.data?.map((field) => {
          return field?.trip_occurrence_field;
        })}
        payload={operatorTripOccurrenceFieldsPayload}
        setPayload={setOperatorTripOccurrenceFieldsPayload}
        handleFieldChange={handleSetOperatorTripOccurrenceFieldValues}
      />

      {/* WAIVER STATUS */}
      <WaiverStatusForm
        isEditMode={isEditMode}
        originalValues={originalValues}
        payload={linkedTripOccurrence}
        setPayload={setLinkedTripOccurrence}
        handleFieldChange={handleFieldChange}
        formType={"linked"}
      />

      <div className="form-control">
        <label className="label">
          <span className="label-text">
            Comments
            <span className="text-error">*</span>
          </span>
        </label>
        <TextArea
          value={linkedTripOccurrence.comments}
          handleOnChange={(event) =>
            handleFieldChange("comments", event.target.value, "linked")
          }
        />
      </div>
    </div>
  );
}
