import { useQuery } from "@tanstack/react-query";
import { useFetchFunctions } from "../services/trips";
import { formatInTimeZone } from "date-fns-tz";
import { useParams } from "react-router-dom";
import { AuditLogEntry } from "../types/auditlog";

export default function AuditLog({
  auditLogDrawerIsOpen,
  setAuditLogDrawerIsOpen,
  isAuthenticated,
}) {
  const { fetchAuditLog } = useFetchFunctions();

  const { operator_short_name, service_date, trip_name } = useParams();

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ["audit_log", operator_short_name, service_date, trip_name],
    queryFn: () => fetchAuditLog(operator_short_name, service_date, trip_name),
    staleTime: 1000 * 10,
    enabled: isAuthenticated && auditLogDrawerIsOpen,
  });

  const TimeStamp = ({ timestamp }) => (
    <td>{formatInTimeZone(timestamp, "America/New_York", "MM/dd/yy - h:mm a")}</td>
  );

  const ChangeRow = ({
    contentType,
    objectRepr,
    actor,
    fromValue,
    toValue,
    timestamp,
  }) => (
    <tr>
      <td>
        {"<"}
        {contentType}
        {"> "}
        <span className="font-bold">{objectRepr}</span>
        {" changed from "}
        <span className="font-bold">{fromValue}</span>
        {" to "}
        <span className="font-bold">{toValue}</span>
      </td>
      <td>{actor || "System"}</td>
      <TimeStamp timestamp={timestamp} />
    </tr>
  );

  return (
    <div className="drawer-side z-30">
      <label htmlFor="audit-log-drawer" className="drawer-overlay"></label>
      <div className="min-h-full w-full md:w-1/2 xl:w-1/3 bg-base-100 p-5 text-base-content prose">
        <h2>Audit Log</h2>
        <label
          onClick={() => setAuditLogDrawerIsOpen(false)}
          className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
        >
          ✕
        </label>

        {isLoading && <div className="w-full h-screen skeleton"></div>}
        <table className="table m-0 lg:text-base">
          <tbody>
            {!isLoading &&
              data?.length &&
              data
                .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                .map((entry: AuditLogEntry, i: number) => {
                  const {
                    serialized_data,
                    action,
                    content_type,
                    object_repr,
                    changes,
                    changes_text,
                    actor,
                    timestamp,
                  } = entry;

                  if (
                    content_type === "triplocationvalue" ||
                    content_type === "tripvalue"
                  ) {
                    const values = changes["value"] || [];
                    return (
                      <ChangeRow
                        key={i}
                        contentType={content_type}
                        objectRepr={object_repr}
                        actor={actor}
                        fromValue={values[0] || ""}
                        toValue={values[1] || ""}
                        timestamp={timestamp}
                      />
                    );
                  }

                  if (
                    action === "update" &&
                    (content_type === "triplocation" || content_type === "trip" || content_type === "tripoccurrence")
                  ) {
                    if (!changes && !changes_text) return null;
                    if (!changes && changes_text) {
                      return (
                        <tr key={i}>
                          <td>
                            <span>{changes_text}</span>
                          </td>
                          <td>{actor}</td>
                          <TimeStamp timestamp={timestamp} />
                        </tr>
                      );
                    }

                    for (const [key, values] of Object.entries(changes)) {
                      const objectRep = `${object_repr}.${key}`;
                      return (
                        <ChangeRow
                          key={i}
                          contentType={content_type}
                          objectRepr={objectRep}
                          actor={actor}
                          fromValue={values[0] || ""}
                          toValue={values[1] || ""}
                          timestamp={timestamp}
                        />
                      );
                    }
                  }

                  if (action === "create" && content_type === "triplocation") {
                    return (
                      <tr key={i}>
                        <td>
                          {"<"}
                          {content_type}
                          {"> "}
                          <span className="font-bold">{changes["location"][1]}</span>
                          {" added to "}
                          <span className="font-bold">{"Trip Locations"}</span>
                        </td>
                        <td>{actor || "System"}</td>
                        <TimeStamp timestamp={timestamp} />
                      </tr>
                    );
                  }

                  if (action === "create" && content_type === "trip") {
                    return (
                      <tr key={i}>
                        <td>
                          {"<"}
                          {content_type}
                          {"> "}
                          <span className="font-bold">
                            {serialized_data?.trip_name}
                          </span>
                          {" created"}
                          <br />
                          {serialized_data?.locations && (
                            <ul>
                              {serialized_data.locations.map((location, i) => (
                                <li key={i}>
                                  {location.location}, Seq {location.sequence}
                                </li>
                              ))}
                            </ul>
                          )}
                        </td>
                        <td>{actor || "System"}</td>
                        <TimeStamp timestamp={timestamp} />
                      </tr>
                    );
                  }

                  return (
                    <tr key={i}>
                      <td>
                        {"<"}
                        {content_type}
                        {"> "} {action}
                        {changes_text && ": " + changes_text}
                      </td>
                      <td>{actor || "System"}</td>
                      <TimeStamp timestamp={timestamp} />
                    </tr>
                  );
                })}
          </tbody>
        </table>

        <div className="flex gap-2">
          <button
            onClick={() => {
              setAuditLogDrawerIsOpen(false);
            }}
            className="btn btn-primary btn-outline"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
