import { UserContext } from "../../utils/Contexts";
import { useContext, useState } from "react";
import { APPROVED, DENIED, REQUESTED, NOT_REQUESTED } from "../../constants/constants";
import { WaiverStatus } from "../trip-detail/trip-occurrences/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faX,
    faR,
    faN,
} from "@fortawesome/pro-regular-svg-icons";
import useFetchFunctions from "../../services/trips";
import { format } from "date-fns-tz";
import { useEditTripOccurrence } from "../../hooks/useEditTripOccurrence";
import toast from "react-hot-toast";
import LoadingSpinner from "../../icons/LoadingSpinner";

const Button = ({ icon, className, onClick, buttonWasClicked, isLoading, enabled }) => (
    <button
        disabled={isLoading}
        onClick={(event) => {
            event.stopPropagation();
            onClick(event);
        }}
        className={`btn btn-outline !h-[22px] !min-h-[22px] !w-[22px] !min-w-[22px] px-0 ${className}`}>
        {buttonWasClicked ? <LoadingSpinner /> : <FontAwesomeIcon icon={icon} />}
    </button>
);

// For users that “Can Request”:
// The Waiver Status of "Not Requested" can be changed to “Requested"
// The Waiver Status of “Requested" can be changed to "Not Requested”

// For users that “Can Respond”:
// The Waiver Status of “Requested” can be changed to “Approved” or “Denied”
// The Waiver Status of “Approved” can be changed to “Denied”
// The Waiver Status of “Denied” can be changed to “Approved”

export default function WaiverStatusQuickChange({ waiver_status, delay, selectedOperator }) {
    const operator_short_name = selectedOperator?.value?.short_name;
    const service_date = format(delay?.service_date, "yyyy-MM-dd");
    const trip = { trip_name: delay?.trip_name };
    const [isLoading, setIsLoading] = useState("");

    const { editTripOccurrence } = useFetchFunctions();
    const { currentUserContext } = useContext(UserContext);

    const canRequestWaiver = currentUserContext?.waiver_permissions?.can_request_waiver
    const canRespondToWaiver = currentUserContext?.waiver_permissions?.can_respond_to_waiver

    const requestButtonEnabled = canRequestWaiver && waiver_status === NOT_REQUESTED;
    const notRequestedButtonEnabled = canRequestWaiver && waiver_status === REQUESTED;

    const approvedButtonEnabled = canRespondToWaiver && (waiver_status === REQUESTED || waiver_status === DENIED);
    const deniedButtonEnabled = canRespondToWaiver && (waiver_status === REQUESTED || waiver_status === APPROVED);

    const { editTripOccurrenceHandler } = useEditTripOccurrence({
        operator_short_name,
        service_date,
        trip,
        setPostingOccurrence: () => { },
        resetFormState: () => { },
        setOccurrenceDrawer: () => { },
        setTripOccurrenceType: () => { },
        toast,
        editTripOccurrence,
        waiverQuickChange: true,
    });

    const handleWaiverChange = async (status) => {
        const payload = {
            occurrence: {},
            trip_occurrence: {
                waiver_status: status,
                trip_occurrence_id: delay?.trip_occurrence_id,
            }
        };

        setIsLoading(status);

        await editTripOccurrenceHandler(payload).then(() => { setIsLoading("") }).catch(() => { setIsLoading("") });
    }

    const buttonConfig = {
        [NOT_REQUESTED]: [
            ...(canRequestWaiver ? [
                { icon: faR, className: "btn-warning", onClick: () => handleWaiverChange(REQUESTED), name: REQUESTED, enabled: requestButtonEnabled }
            ] : []),
            ...(canRequestWaiver && canRespondToWaiver ? [
                { icon: faCheck, className: "btn-success", onClick: () => handleWaiverChange(APPROVED), name: APPROVED, enabled: approvedButtonEnabled },
                { icon: faX, className: "btn-error", onClick: () => handleWaiverChange(DENIED), name: DENIED, enabled: deniedButtonEnabled }
            ] : [])
        ],
        [REQUESTED]: [
            ...(canRequestWaiver ? [
                { icon: faN, className: " ", onClick: () => handleWaiverChange(NOT_REQUESTED), name: NOT_REQUESTED, enabled: notRequestedButtonEnabled }
            ] : []),
            ...(canRespondToWaiver ? [
                { icon: faCheck, className: "btn-success", onClick: () => handleWaiverChange(APPROVED), name: APPROVED, enabled: approvedButtonEnabled },
                { icon: faX, className: "btn-error", onClick: () => handleWaiverChange(DENIED), name: DENIED, enabled: deniedButtonEnabled }
            ] : [])
        ],
        [APPROVED]: [
            ...(canRespondToWaiver ? [
                { icon: faR, className: "btn-warning", onClick: () => handleWaiverChange(REQUESTED), name: REQUESTED, enabled: requestButtonEnabled },
                { icon: faX, className: "btn-error", onClick: () => handleWaiverChange(DENIED), name: DENIED, enabled: deniedButtonEnabled }
            ] : []),
            ...(canRequestWaiver && canRespondToWaiver ? [
                { icon: faN, className: " ", onClick: () => handleWaiverChange(NOT_REQUESTED), name: NOT_REQUESTED, enabled: notRequestedButtonEnabled }
            ] : [])
        ],
        [DENIED]: [
            ...(canRespondToWaiver ? [
                { icon: faR, className: "btn-warning", onClick: () => handleWaiverChange(REQUESTED), name: REQUESTED, enabled: requestButtonEnabled },
                { icon: faCheck, className: "btn-success", onClick: () => handleWaiverChange(APPROVED), name: APPROVED, enabled: approvedButtonEnabled }
            ] : []),
            ...(canRequestWaiver && canRespondToWaiver ? [
                { icon: faN, className: " ", onClick: () => handleWaiverChange(NOT_REQUESTED), name: NOT_REQUESTED, enabled: notRequestedButtonEnabled }
            ] : [])
        ]
    };



    return (
        <td>
            <div className="flex gap-2 items-center">
                <WaiverStatus waiver_status={waiver_status} />
                {!canRequestWaiver && !canRespondToWaiver ? <></> :
                    <div className="flex gap-2">
                        {buttonConfig[waiver_status]?.map(({ icon, className, onClick, name, enabled }, index) => {
                            return (
                                <Button key={index} icon={icon} className={className} onClick={onClick} buttonWasClicked={isLoading === name} isLoading={isLoading} enabled={enabled} />
                            )

                        })}
                    </div>
                }
            </div>
        </td>
    );
}