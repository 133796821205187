import SingleSelect from "../../SingleSelect";
import NumberField from "../../NumberField";

export default function LinearAssetsField({
    isDisabled,
    isRequired,
    linearAssets,
    interdependentFields,
    handleOnChange,
    showStartAndEnd,
    handleStartChange,
    handleEndChange,
}) {
    const currentLinearAsset = linearAssets?.find((linear_asset) => {
        if (interdependentFields?.linear_asset?.value?.name) {
            return (linear_asset?.name === interdependentFields?.linear_asset?.value?.name);
        }
        if (interdependentFields?.linear_asset?.value) {
            return (linear_asset?.name === interdependentFields?.linear_asset?.value);
        }
    })
    const currentLinearAssetStart = currentLinearAsset?.start_measurement
    const currentLinearAssetEnd = currentLinearAsset?.end_measurement
    return (
        <>
            <div className="form-control">
                <label className="label">
                    <span className="label-text">
                        Linear Asset {isRequired && <span className="text-error">*</span>}
                    </span>
                </label>
                <SingleSelect
                    isDisabled={linearAssets?.isLoading || isDisabled}
                    isLoading={linearAssets?.isLoading}
                    placeholder="Pick one"
                    value={interdependentFields.linear_asset}
                    handleOnChange={(linear_asset) => handleOnChange(linear_asset)}
                    options={linearAssets?.map((linearAsset) => {
                        return {
                            id: linearAsset.id,
                            label: linearAsset.name,
                            value: linearAsset,
                        };
                    })
                    }
                />
            </div>
            {showStartAndEnd && (
                <div className="flex justify-start gap-4">
                    <div>
                        <label className="label">
                            <span className="label-text">
                                Start {isRequired && <span className="text-error">*</span>}
                            </span>
                        </label>
                        <NumberField
                            className="max-w-20"
                            placeholder="00"
                            min={currentLinearAssetStart || 0}
                            max={currentLinearAssetEnd - 1 || 0}
                            disabled={!interdependentFields?.linear_asset}
                            value={interdependentFields?.linear_asset_start}
                            handleOnChange={(event) => {
                                const newValue = Number(event.target.value);
                                if (
                                    newValue >
                                    currentLinearAssetEnd - 1
                                ) {
                                    handleStartChange(currentLinearAssetEnd - 1);
                                } else if (
                                    newValue <
                                    currentLinearAssetStart
                                ) {
                                    handleStartChange(currentLinearAssetStart);
                                } else if (
                                    newValue >= currentLinearAssetEnd
                                ) {
                                    return;
                                } else {
                                    handleStartChange(newValue);
                                }
                            }}
                        />
                    </div>
                    <div>
                        <label className="label">
                            <span className="label-text">
                                End {isRequired && <span className="text-error">*</span>}
                            </span>
                        </label>
                        <NumberField
                            placeholder="00"
                            disabled={!interdependentFields.linear_asset}
                            min={currentLinearAssetStart + 1 || 0}
                            max={currentLinearAssetEnd || 0}
                            className="max-w-20"
                            value={interdependentFields.linear_asset_end}
                            handleOnChange={(event) => {
                                const newValue = Number(event.target.value);
                                if (
                                    newValue >
                                    currentLinearAssetEnd
                                ) {
                                    handleEndChange(currentLinearAssetEnd);
                                } else if (
                                    newValue <=
                                    currentLinearAssetStart + 1
                                ) {
                                    handleEndChange(currentLinearAssetStart + 1);
                                } else if (
                                    newValue <= currentLinearAssetStart
                                ) {
                                    return;
                                } else {
                                    handleEndChange(newValue);
                                }
                            }}
                        />
                    </div>
                </div>
            )}
        </>
    )
}
