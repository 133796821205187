import { formatUnderscoreCasing } from "../../utils/helperFunctions";
import TextField from "../TextField";
import { useState, useEffect, useRef } from "react";
import { useFetchFunctions } from "../../services/trips";
import LoadingSpinner from "../../icons/LoadingSpinner";
import toast from "react-hot-toast";
import SingleSelect from "../SingleSelect";

const sortBySequence = (arr) => {
  return arr.sort((a, b) => a.sequence - b.sequence);
};

const defaultSearchPayload = {
  carrier_code: "",
  serial_number: "",
};

function subtractHoursFromDate(dateTime, hours) {
  const date = new Date(dateTime);
  const updatedDate = new Date(date.getTime() - hours * 60 * 60 * 1000);

  return updatedDate.toISOString().replace("Z", dateTime?.slice(-6));
}

function addHoursToDate(dateTime, hours) {
  const date = new Date(dateTime);
  const updatedDate = new Date(date.getTime() + hours * 60 * 60 * 1000);

  return updatedDate.toISOString().replace("Z", dateTime?.slice(-6));
}

function formatDateForUrl(date: Date): string {
  const pad = (num: number) => (num < 10 ? '0' : '') + num;

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());

  const timezoneOffset = -date.getTimezoneOffset();
  const sign = timezoneOffset >= 0 ? '+' : '-';
  const offsetHours = pad(Math.floor(Math.abs(timezoneOffset) / 60));
  const offsetMinutes = pad(Math.abs(timezoneOffset) % 60);

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${sign}${offsetHours}:${offsetMinutes}`;
}

export default function VehicleFormation({
  trip,
  operator_short_name,
  service_date,
  showVehicleFormationDrawer,
  setShowVehicleFormationDrawer,
  operator,
}) {
  const [searchPayload, setSearchPayload] = useState(defaultSearchPayload);
  const [searching, setSearching] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [suggestedUpdating, setSuggestedUpdating] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedVehicleFormation, setSelectedVehicleFormation] = useState();

  const allDeparturesNull = trip?.trip_locations?.every(
    location => location?.scheduled_departure === null
  );

  const allArrivalsNull = trip?.trip_locations?.every(
    location => location?.scheduled_arrival === null
  );

  const startServiceDate = new Date(service_date + "T00:00:00");
  startServiceDate.setHours(3, 0, 0, 0);
  const formattedStartServiceDate = formatDateForUrl(startServiceDate);

  const endServiceDate = new Date(service_date + "T00:00:00");
  endServiceDate.setDate(endServiceDate.getDate() + 1);
  endServiceDate.setHours(2, 59, 0, 0);
  const formattedEndServiceDate = formatDateForUrl(endServiceDate);

  const minScheduledDepartureTripLocation = trip?.trip_locations?.reduce((min, current) => {
    if (current?.scheduled_departure === null) return min;
    const minDeparture = new Date(min?.scheduled_departure || Infinity);
    const currentDeparture = new Date(current?.scheduled_departure);

    return currentDeparture < minDeparture ? current : min;
  });

  const maxScheduledArrivalTripLocation = trip?.trip_locations?.reduce((max, current) => {
    if (current?.scheduled_departure === null) return max;
    const maxArrival = new Date(max?.scheduled_arrival || -Infinity);
    const currentArrival = new Date(current?.scheduled_arrival);

    return currentArrival > maxArrival ? current : max;
  });

  const minScheduledDeparture = subtractHoursFromDate(minScheduledDepartureTripLocation?.scheduled_departure, 16);
  const maxScheduledArrival = addHoursToDate(maxScheduledArrivalTripLocation?.scheduled_arrival, 16);

  const vehicle_formation_values = trip.assigned_vehicle_formation
    ? trip.assigned_vehicle_formation.vehicle_formation_values
    : [];
  const vehicle_formation_vehicles = trip.assigned_vehicle_formation
    ? trip.assigned_vehicle_formation.vehicle_formation_vehicles
    : [];

  const suggestedVehicleFormationValues = trip.suggested_vehicle_formation
    ? trip.suggested_vehicle_formation.vehicle_formation_values
    : [];
  const suggestedVehicleFormationVehicles = trip.suggested_vehicle_formation
    ? trip.suggested_vehicle_formation.vehicle_formation_vehicles
    : [];

  const sortedVehicleFormationVehicles = vehicle_formation_vehicles
    ? sortBySequence(vehicle_formation_vehicles.slice())
    : [];

  const sortedSuggestedVehicleFormationVehicles = suggestedVehicleFormationVehicles
    ? sortBySequence(suggestedVehicleFormationVehicles.slice())
    : [];

  const { fetchVehicleFormations, assignVehicleFormation } = useFetchFunctions();

  const handleFetchVehicleFormation = async () => {
    setSearching(true);
    setSelectedVehicleFormation(null);

    let fromDateTime = minScheduledDeparture;
    let toDateTime = maxScheduledArrival;

    if (allDeparturesNull && allArrivalsNull) {
      fromDateTime = formattedStartServiceDate;
      toDateTime = formattedEndServiceDate;
    }

    try {
      await fetchVehicleFormations(
        operator_short_name,
        searchPayload.carrier_code?.value,
        searchPayload.serial_number,
        fromDateTime,
        toDateTime
      ).then((res) => {
        console.log("res", res);
        setSearchResults(res);
        if (res.length === 1) {
          setSelectedVehicleFormation(res[0]);
        }
        setSearching(false);
      });
    } catch (error) {
      console.log("Error:", error);
      setSearching(false);
    }
  };

  const handleAssignVehicleFormation = async () => {
    setUpdating(true);
    const payload = {
      assigned_vehicle_formation_id: selectedVehicleFormation?.id,
    };
    try {
      await assignVehicleFormation(
        operator_short_name,
        service_date,
        trip.trip_name,
        payload
      ).then((res) => {
        console.log("res", res);
        trip.assigned_vehicle_formation = res.assigned_vehicle_formation;
        setUpdating(false);
        setShowVehicleFormationDrawer(false);
        setSearchPayload(defaultSearchPayload);
        setSearchResults([]);
        toast.success(`Trip ${trip?.trip_name} saved`);
      });
    } catch (error) {
      console.log("Error:", error);
      setUpdating(false);
    }
  };

  const handleAssignSuggestedVehicleFormation = async () => {
    setSuggestedUpdating(true);
    const payload = {
      assigned_vehicle_formation_id: trip?.suggested_vehicle_formation?.id,
    };
    try {
      await assignVehicleFormation(
        operator_short_name,
        service_date,
        trip.trip_name,
        payload
      ).then((res) => {
        console.log("res", res);
        trip.assigned_vehicle_formation = res.assigned_vehicle_formation;
        toast.success(`Trip ${trip?.trip_name} saved`);
        setSuggestedUpdating(false);
      });
    } catch (error) {
      console.log("Error:", error);
      setSuggestedUpdating(false);
    }
  };

  const VehicleFormationResultCard = ({ vehicleFormationResult }) => {
    const isSelected = vehicleFormationResult?.id === selectedVehicleFormation?.id;
    const vehicleFormationValues = vehicleFormationResult?.vehicle_formation_values;

    const sortedVehicleFormation = sortBySequence(
      vehicleFormationResult?.vehicle_formation_vehicles?.slice()
    );

    return (
      <div
        className={`alert my-6
          ${isSelected ? "bg-success/10 border-success" : "bg-white border-[#808080] hover:border-primary hover:bg-primary/10 hover:cursor-pointer"}
        `}
        onClick={() => setSelectedVehicleFormation(vehicleFormationResult)}
      >
        <div>
          <div className="flex flex-wrap gap-x-4 mb-3">
            {vehicleFormationValues.map((value, index) => {
              return (
                <div className="flex gap-2 items-center" key={index}>
                  {value.vehicle_formation_field.label}
                  <div className="badge badge-sm badge-primary">{value.value}</div>
                </div>
              );
            })}
          </div>
          <div className="flex flex-col sm:flex-row gap-4 sm:justify-between sm:items-center">
            <div className="flex flex-wrap gap-1">
              {sortedVehicleFormation.map((vehicle, index) => (
                <div className="badge badge-md badge-outline" key={index}>
                  {vehicle?.vehicle?.serial_number}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (operator?.vehicle_carrier_codes && operator?.vehicle_carrier_codes?.length === 1) {
      setSearchPayload({
        ...searchPayload,
        carrier_code: {
          label: operator.vehicle_carrier_codes[0],
          value: operator.vehicle_carrier_codes[0],
        },
      });
    }
  }, [showVehicleFormationDrawer, setShowVehicleFormationDrawer]);

  const inputRef = useRef(null);
  useEffect(() => {
    if (showVehicleFormationDrawer && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showVehicleFormationDrawer]);

  return (
    <div className="drawer drawer-end">
      <input
        id="change-vehicle-formation-drawer"
        type="checkbox"
        className="drawer-toggle"
        checked={showVehicleFormationDrawer}
        onChange={() => { }}
      />
      <div className="drawer-content">
        <div className="card card-compact bg-base-100 border border-base-300 prose">
          <div className="card-header py-2 px-4 border-b">
            <div className="lg:flex justify-between">
              <h3 className="m-0">Equipment</h3>
            </div>
          </div>
          <table className="table m-0">
            <tbody>
              <tr>
                <td>
                  <div className="flex flex-wrap gap-4 mb-3">
                    {sortedVehicleFormationVehicles.length > 0 && (
                      <div className="badge badge-sm badge-success">Assigned</div>
                    )}
                    {vehicle_formation_values.map((value, index) => {
                      return (
                        <div className="flex gap-2" key={index}>
                          {
                            value.vehicle_formation_field.label
                          }
                          <div className="badge badge-sm badge-primary">
                            {value.value}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex flex-col sm:flex-row gap-4 sm:justify-between sm:items-center">
                    {!sortedVehicleFormationVehicles.length ? (
                      <div className="italic">No equipment assigned</div>
                    ) : (
                      <div className="flex flex-wrap gap-1">
                        {sortedVehicleFormationVehicles.map((vehicle, index) => {
                          return (
                            <div
                              className="badge badge-md badge-outline"
                              key={index}
                            >
                              {vehicle?.vehicle?.serial_number}
                            </div>
                          );
                        })}
                      </div>
                    )}
                    <button
                      onClick={() => setShowVehicleFormationDrawer(true)}
                      className="btn btn-sm btn-outline btn-primary hidden md:block"
                    >
                      Change
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="flex flex-wrap gap-4 mb-3">
                    {sortedSuggestedVehicleFormationVehicles.length > 0 && (
                      <div className="badge badge-sm badge-info">Detected</div>
                    )}
                    {suggestedVehicleFormationValues.map((value, index) => (
                      <div className="flex gap-2" key={index}>
                        {value.vehicle_formation_field.label}
                        <div className="badge badge-sm badge-primary">
                          {value.value}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="flex flex-col sm:flex-row gap-4 sm:justify-between sm:items-center">
                    {!sortedSuggestedVehicleFormationVehicles.length ? (
                      <div className="italic">No equipment suggested</div>
                    ) : (
                      <div className="flex flex-wrap gap-1">
                        {sortedSuggestedVehicleFormationVehicles.map(
                          (vehicle, index) => (
                            <div
                              className="badge badge-md badge-outline"
                              key={index}
                            >
                              {vehicle?.vehicle?.serial_number}
                            </div>
                          )
                        )}
                      </div>
                    )}
                    <button
                      disabled={
                        !trip?.suggested_vehicle_formation ||
                        trip?.assigned_vehicle_formation?.id ===
                        trip?.suggested_vehicle_formation?.id
                      }
                      className="btn btn-sm btn-outline hidden md:block"
                      onClick={() => handleAssignSuggestedVehicleFormation()}
                    >
                      Assign
                      {suggestedUpdating && <LoadingSpinner />}
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="drawer-side z-30">
        <label
          htmlFor="change-vehicle-formation-drawer"
          className="drawer-overlay"
        ></label>
        <div className="min-h-full w-full md:w-1/2 xl:w-1/3 bg-base-100 p-5 text-base-content prose">
          <h2>Change Formation</h2>
          <label
            onClick={() => setShowVehicleFormationDrawer(false)}
            htmlFor="change-vehicle-formation-drawer"
            className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
          >
            ✕
          </label>

          <div className="form-control">
            <label className="label">
              <span className="label-text">
                Enter Vehicle Carrier Code and Serial # to find formation{" "}
                <span className="text-error">*</span>
              </span>
            </label>
            <div className="flex gap-2">
              <div className="join">
                <div className="join-item w-full">
                  <SingleSelect
                    isDisabled={searching || !operator || updating || operator?.vehicle_carrier_codes?.length <= 1}
                    placeholder={"Carrier Code"}
                    value={searchPayload.carrier_code}
                    options={operator?.vehicle_carrier_codes && operator?.vehicle_carrier_codes?.map((carrier_code) => ({
                      label: carrier_code,
                      value: carrier_code,
                    }))}
                    handleOnChange={(selectedOption) =>
                      setSearchPayload({
                        ...searchPayload,
                        carrier_code: selectedOption,
                      })
                    }
                  />
                </div>
                <TextField
                  reference={inputRef}
                  className="join-item w-full"
                  placeholder={"Serial #"}
                  value={searchPayload.serial_number}
                  handleOnChange={(event) =>
                    setSearchPayload({
                      ...searchPayload,
                      serial_number: event.target.value,
                    })
                  }
                />
              </div>

              <button
                disabled={searching}
                className="btn btn-sm btn-primary lg:btn-md"
                onClick={() => handleFetchVehicleFormation()}
              >
                Search
                {searching && <LoadingSpinner />}
              </button>
            </div>
          </div>

          {Array.isArray(searchResults) ? (
            searchResults?.map((vehicleFormation, index) => {
              return (
                <VehicleFormationResultCard
                  key={vehicleFormation?.id || index}
                  vehicleFormationResult={vehicleFormation}
                />
              );
            })
          ) : (
            <div className="mt-5 italic text-sm">{searchResults}</div>
          )}

          <div className="flex gap-2 mt-5">
            <button
              disabled={updating || searching}
              onClick={() => {
                setSearchResults([]);
                setSearchPayload(defaultSearchPayload);
                setShowVehicleFormationDrawer(false);
              }}
              className="btn btn-primary btn-outline"
            >
              Cancel
            </button>
            <button
              disabled={
                selectedVehicleFormation?.id ===
                trip?.assigned_vehicle_formation?.id || updating
              }
              className={`btn btn-primary ${Array.isArray(searchResults) && searchResults.length >= 1 ? "" : "btn-disabled"}`}
              onClick={() => handleAssignVehicleFormation()}
            >
              Update
              {updating && <LoadingSpinner />}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
