import LinearAssetsField from "./LinearAssetField";
import LocationsField from "./LocationField";
import StationaryAssetsField from "./StationaryAssetField";

export default function InterdependentUnusualOccurrenceFormFields({
    locations,
    linearAssets,
    selectedOperator,
    stationaryAssets,
    interdependentFields,
    handleInterdependentFieldChange,
    occurrenceCause,
}) {
    const linearAssetIsRequired = occurrenceCause?.linear_asset === "REQUIRED";
    const locationIsRequired = occurrenceCause?.location === "REQUIRED";
    const stationaryAssetIsRequired = occurrenceCause?.stationary_asset === "REQUIRED";

    const locationFieldDisabled = !selectedOperator || !interdependentFields.linear_asset || !occurrenceCause;
    const linearAssetFieldDisabled = !selectedOperator || !occurrenceCause;
    const stationaryAssetFieldDisabled = !selectedOperator || !interdependentFields.linear_asset || !occurrenceCause;

    //selected values
    const selectedLocation = interdependentFields.location;
    const selectedLinearAsset = interdependentFields.linear_asset;
    const selectedStationaryAsset = interdependentFields.stationary_asset;


    //simple filtering on two dependent fields
    const filteredLocations = locations?.data?.filter((location) => {
        if (locations.isLoading) {
            return [];
        }
        if (!selectedStationaryAsset) {
            return location;
        }
        if (selectedStationaryAsset) {
            return location.id === selectedStationaryAsset.location;
        } else {
            return location;
        }
    });
    const filteredStationaryAssets = stationaryAssets?.data?.filter((stationaryAsset) => {
        if (stationaryAssets.isLoading) {
            return [];
        }
        if (!selectedLocation) {
            return stationaryAsset;
        }
        if (selectedLocation) {
            return stationaryAsset.id === selectedLocation.value;
        } else {
            return stationaryAsset;
        }
    });

    const handleLinearAssetsFieldChange = (linear_asset) => {
        if (!linear_asset) {
            handleInterdependentFieldChange("stationary_asset", null);
            handleInterdependentFieldChange("location", null);
            handleInterdependentFieldChange("linear_asset", null);
            handleInterdependentFieldChange("linear_asset_start", "");
            handleInterdependentFieldChange("linear_asset_end", "");
        } else {
            handleInterdependentFieldChange("linear_asset", linear_asset);
            handleInterdependentFieldChange("linear_asset_start", linear_asset?.value?.start_measurement);
            handleInterdependentFieldChange("linear_asset_end", linear_asset?.value?.end_measurement);
            handleInterdependentFieldChange("stationary_asset", null);
            handleInterdependentFieldChange("location", null);
        }
    }
    const handleLinearAssetStartFieldChange = (start) => handleInterdependentFieldChange("linear_asset_start", start);
    const handleLinearAssetEndFieldChange = (end) => handleInterdependentFieldChange("linear_asset_end", end);
    const handleLocationFieldChange = (location) => handleInterdependentFieldChange("location", location);

    return (
        <>
            <LinearAssetsField
                isDisabled={linearAssetFieldDisabled}
                isRequired={linearAssetIsRequired}
                linearAssets={linearAssets}
                interdependentFields={interdependentFields}
                handleOnChange={handleLinearAssetsFieldChange}
                handleStartChange={handleLinearAssetStartFieldChange}
                handleEndChange={handleLinearAssetEndFieldChange}
                showStartAndEnd={linearAssetIsRequired || occurrenceCause?.linear_asset === "OPTIONAL"}
            />
            <LocationsField
                isDisabled={locationFieldDisabled}
                isRequired={locationIsRequired}
                locations={filteredLocations}
                interdependentFields={interdependentFields}
                handleOnChange={handleLocationFieldChange}
            />
            <StationaryAssetsField
                isDisabled={stationaryAssetFieldDisabled}
                isRequired={stationaryAssetIsRequired}
                stationaryAssets={filteredStationaryAssets}
                interdependentFields={interdependentFields}
                handleInterdependentFieldChange={handleInterdependentFieldChange}
            />
        </>
    )
}
