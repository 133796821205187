import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/pro-regular-svg-icons";

import { PrimaryStatus, WaiverStatus } from "./utils";

export default function TableRow({
  trip_occurrence,
  index,
  setOccurrenceToBeDeleted,
  handleClickEditOccurrence,
  visibleTableColumns,
}) {
  const {
    delay_minutes,
    primary,
    operator_trip_occurrence_cause,
    comments,
    waiver_status,
    occurrence,
    parent_trip_occurrence,
  } = trip_occurrence || {};

  const parentOccurrenceOrTripOccurrence = occurrence ?? parent_trip_occurrence;
  const responsible_party = parentOccurrenceOrTripOccurrence?.responsible_party || "N/A";
  const cause = occurrence?.occurrence_cause || `Residual - ${parent_trip_occurrence?.occurrence_cause}` || "Residual";

  const columnIsVisible = (columnName) => {
    return visibleTableColumns.some(
      (col) => col.name === columnName && col.visible === true
    );
  };

  const locationDisplayPriority = () => {
    if (parentOccurrenceOrTripOccurrence?.stationary_asset) {
      return parentOccurrenceOrTripOccurrence?.stationary_asset;
    } else if (parentOccurrenceOrTripOccurrence?.location) {
      return parentOccurrenceOrTripOccurrence?.location?.name ?? parentOccurrenceOrTripOccurrence?.location;
    } else if (parentOccurrenceOrTripOccurrence?.linear_asset) {
      return `${parentOccurrenceOrTripOccurrence?.linear_asset?.name ?? parentOccurrenceOrTripOccurrence?.linear_asset} (${parentOccurrenceOrTripOccurrence?.linear_asset?.start_measurement ?? parentOccurrenceOrTripOccurrence?.linear_asset_start} - ${parentOccurrenceOrTripOccurrence?.linear_asset?.end_measurement ?? parentOccurrenceOrTripOccurrence?.linear_asset_end})`;
    } else return "N/A";
  }

  return (
    <tr>
      {columnIsVisible("location") && <td className="truncate">{locationDisplayPriority()}</td>}
      {columnIsVisible("delay_minutes") && <td>{delay_minutes}</td>}
      {columnIsVisible("primary_trip_occurrence") && (
        <td>
          <PrimaryStatus is_primary={occurrence?.primary} />
        </td>
      )}
      {columnIsVisible("cause") && <td className="truncate">{cause}</td>}
      {columnIsVisible("responsible_party") && <td>{responsible_party}</td>}
      {columnIsVisible("comments") && (
        <td className="truncate max-w-48" title={`${comments}`}>
          {comments}
        </td>
      )}
      {columnIsVisible("waiver_status") && (
        <td>
          <WaiverStatus waiver_status={waiver_status} />
        </td>
      )}
      <td>
        <li className="dropdown dropdown-left dropdown-end not-prose">
          <button className="btn btn-sm btn-ghost text-primary" tabIndex={0}>
            <FontAwesomeIcon icon={faEllipsis} size="lg" />
          </button>
          <ul
            tabIndex={0}
            className="menu p-1 shadow dropdown-content z-10 bg-base-100 rounded-box w-32"
          >
            <li>
              <label
                htmlFor="add-occurrence-drawer"
                onClick={() => handleClickEditOccurrence(trip_occurrence)}
              >
                Edit
              </label>
            </li>
            <li>
              <a onClick={() => setOccurrenceToBeDeleted(trip_occurrence)}
              >Delete</a>
            </li>
          </ul>
        </li>
      </td>
    </tr>
  );
}
