import { useState } from "react";
import TextField from "../TextField";
import NumberField from "../NumberField";
import Checkbox from "../Checkbox";
import SingleSelect from "../SingleSelect";
import DateTimePicker from "../DateTimePicker";
import { useFetchFunctions } from "../../services/trips";
import { useQuery } from "@tanstack/react-query";
import LoadingSpinner from "../../icons/LoadingSpinner";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { handlePotentialBooleanStrings } from "../../utils/helperFunctions";
import { formatInTimeZone } from "date-fns-tz";

const RenderValue = ({ type, value }) => {
  if (type === "BOOLEAN") {
    return (
      <>
        {handlePotentialBooleanStrings(value) ? (
          <FontAwesomeIcon icon={faCheck} className="text-primary" />
        ) : (
          <FontAwesomeIcon icon={faTimes} className="text-error" />
        )}
      </>
    );
  } else if (type === "DATETIME") {
    return (
      <>
        {value && formatInTimeZone(value, "America/New_York", "MM/dd/yy - h:mm a")}
      </>
    );
  } else {
    return <>{value}</>;
  }
};

export default function TripParameters({
  trip,
  operator_short_name,
  service_date,
  tripParametersDrawerIsOpen,
  setTripParametersDrawerIsOpen,
}) {
  const { fetchOperatorTripFields, editTripValues } = useFetchFunctions();
  const [valuesToBeEdited, setValuesToBeEdited] = useState({});
  const [isSavingValues, setIsSavingValues] = useState(false);

  const {
    isLoading,
    isError,
    data: operatorTripFields,
    error,
  } = useQuery({
    queryKey: ["operator_trip_fields", operator_short_name],
    queryFn: () => fetchOperatorTripFields(operator_short_name),
    staleTime: 1000 * 10,
  });

  const handleSaveTripValues = async () => {
    const formattedPayload = [];

    for (const fieldName in valuesToBeEdited) {
      const value = { field: fieldName, value: valuesToBeEdited[fieldName]?.value };

      formattedPayload.push(value);
    }

    setIsSavingValues(true);
    try {
      await editTripValues(
        operator_short_name,
        service_date,
        trip.trip_name,
        formattedPayload
      ).then((res) => {
        if (!res.error) {
          setTripParametersDrawerIsOpen(false);
          trip.trip_values = res.trip_values;
          toast.success(`Trip ${trip?.trip_name} saved`);
        }
        setIsSavingValues(false);
        setValuesToBeEdited({});
      });
    } catch (error) {
      setIsSavingValues(false);
    }
  };

  return (
    <div className="drawer drawer-end">
      <input
        id="manage-trip-parameters-drawer"
        type="checkbox"
        className="drawer-toggle"
        checked={tripParametersDrawerIsOpen}
        onChange={() => { }}
      />
      <div className="drawer-content">
        <div className="card card-compact bg-base-100 border border-base-300 prose">
          <div className="card-header py-2 px-4 border-b">
            <div className="flex justify-between">
              <h3 className="m-0">Trip Parameters</h3>
              <button
                className="btn btn-sm btn-primary  hidden md:inline-block"
                onClick={() => setTripParametersDrawerIsOpen(true)}
              >
                Manage
              </button>
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="table table-sm m-0">
              <thead>
                <tr>
                  <th>Parameter Name</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {trip.trip_values?.length < 1 && (
                  <tr>
                    <td colSpan={2} className="italic">
                      No trip parameters found
                    </td>
                  </tr>
                )}
                {trip.trip_values?.map((trip_value, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-base-content/50">
                        {trip_value.operator_trip_field.trip_field.label ||
                          trip_value.operator_trip_field.trip_field.name}
                      </td>
                      <td>
                        <RenderValue
                          type={trip_value?.operator_trip_field?.trip_field?.type}
                          value={trip_value?.value}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="drawer-side z-30">
        <label
          htmlFor="manage-trip-parameters-drawer"
          className="drawer-overlay"
        ></label>
        <div className="min-h-full w-full md:w-1/2 xl:w-1/3 bg-base-100 p-5 text-base-content prose">
          <h2>Manage Trip Parameters</h2>
          <button
            onClick={() => {
              setTripParametersDrawerIsOpen(false);
              setValuesToBeEdited({});
            }}
            className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
          >
            ✕
          </button>

          <table className="table table-sm mt-0">
            <thead>
              <tr>
                <th>Parameter Name</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {operatorTripFields &&
                operatorTripFields.length &&
                operatorTripFields?.map((operatorTripField, i) => {
                  const matchingTripValue = trip?.trip_values?.find(
                    (trip_value) =>
                      trip_value.operator_trip_field.trip_field.name ===
                      operatorTripField.trip_field.name
                  );

                  const fieldName = operatorTripField?.trip_field?.name;

                  return (
                    <tr key={i}>
                      <td>{operatorTripField.trip_field.label}</td>
                      <td>
                        {!operatorTripField?.can_edit ? (
                          <RenderValue
                            value={matchingTripValue?.value}
                            type={operatorTripField?.trip_field?.type}
                          />
                        ) : (
                          <>
                            {operatorTripField?.lookup_list_items?.length > 0 &&
                              operatorTripField.trip_field.type === "TEXT" && (
                                <SingleSelect
                                  isDisabled={!operatorTripField?.can_edit}
                                  placeholder={matchingTripValue?.value}
                                  options={operatorTripField?.lookup_list_items?.map(
                                    (item) => {
                                      return { value: item, label: item };
                                    }
                                  )}
                                  handleOnChange={(event, action) => {
                                    const updatedValues = { ...valuesToBeEdited };
                                    if (
                                      action?.action === "clear" ||
                                      event?.value === matchingTripValue?.value
                                    ) {
                                      delete updatedValues[fieldName];
                                    } else {
                                      updatedValues[fieldName] = {
                                        value: event?.value,
                                        label: event?.label,
                                      };
                                    }
                                    setValuesToBeEdited(updatedValues);
                                  }}
                                  value={valuesToBeEdited[fieldName]}
                                />
                              )}
                            {operatorTripField?.trip_field?.type === "TEXT" &&
                              (operatorTripField?.lookup_list_items?.length === 0 || !operatorTripField.lookup_list_items) && (
                                <TextField
                                  className="w-full"
                                  disabled={!operatorTripField?.can_edit}
                                  placeholder={matchingTripValue?.value}
                                  value={
                                    valuesToBeEdited[
                                      operatorTripField?.trip_field?.name
                                    ]?.value ?? matchingTripValue?.value
                                  }
                                  handleOnChange={(event) => {
                                    const updatedValues = { ...valuesToBeEdited };
                                    if (
                                      event.target.value === matchingTripValue?.value
                                    ) {
                                      delete updatedValues[fieldName];
                                    } else {
                                      updatedValues[fieldName] = {
                                        value: event.target.value,
                                      };
                                    }
                                    setValuesToBeEdited(updatedValues);
                                  }}
                                />
                              )}
                            {operatorTripField.trip_field.type === "INTEGER" && (
                              <NumberField
                                className="w-full"
                                disabled={!operatorTripField?.can_edit}
                                handleOnChange={(event) => {
                                  const updatedValues = { ...valuesToBeEdited };
                                  if (
                                    event.target.value === matchingTripValue?.value
                                  ) {
                                    delete updatedValues[fieldName];
                                  } else {
                                    updatedValues[fieldName] = {
                                      value: event.target.value,
                                    };
                                  }
                                  setValuesToBeEdited(updatedValues);
                                }}
                                value={
                                  valuesToBeEdited[fieldName]?.value ??
                                  trip?.trip_values?.find(
                                    (x) =>
                                      x?.operator_trip_field.trip_field.name ===
                                      operatorTripField.trip_field.name
                                  )?.value
                                }
                              />
                            )}
                            {operatorTripField.trip_field.type === "BOOLEAN" && (
                              <Checkbox
                                string={true}
                                disabled={!operatorTripField?.can_edit}
                                checked={
                                  valuesToBeEdited[fieldName]?.value ??
                                  matchingTripValue?.value
                                }
                                value={
                                  valuesToBeEdited[fieldName]?.value ??
                                  matchingTripValue?.value
                                }
                                handleOnChange={(event) => {
                                  const updatedValues = { ...valuesToBeEdited };
                                  const value = event;
                                  if (matchingTripValue?.value === value) {
                                    delete updatedValues[fieldName];
                                  } else {
                                    updatedValues[fieldName] = { value };
                                  }
                                  setValuesToBeEdited(updatedValues);
                                }}
                              />
                            )}
                            {operatorTripField.trip_field.type === "DATETIME" && (
                              <DateTimePicker
                                disabled={!operatorTripField?.can_edit}
                                handleOnChange={(event) => {
                                  const updatedValues = { ...valuesToBeEdited };
                                  if (matchingTripValue?.value === event) {
                                    delete updatedValues[fieldName];
                                  } else {
                                    updatedValues[fieldName] = { value: event };
                                  }
                                  setValuesToBeEdited(updatedValues);
                                }}
                                value={
                                  valuesToBeEdited[fieldName]?.value ??
                                  trip?.trip_values?.find(
                                    (x) =>
                                      x?.operator_trip_field.trip_field.name ===
                                      operatorTripField.trip_field.name
                                  )?.value
                                }
                              />
                            )}
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <div className="flex gap-2">
            <button
              className="btn btn-primary btn-outline"
              onClick={() => {
                setTripParametersDrawerIsOpen(false);
                setValuesToBeEdited({});
              }}
            >
              Cancel
            </button>
            <button
              disabled={Object.keys(valuesToBeEdited).length === 0 || isSavingValues}
              className="btn btn-primary"
              onClick={() => handleSaveTripValues()}
            >
              Save
              {isSavingValues && <LoadingSpinner />}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
