import SingleSelect from "../../SingleSelect";

export default function StationaryAssetsField({
    isDisabled,
    stationaryAssets,
    stationaryAssetTypes,
    interdependentFields,
    handleInterdependentFieldChange,
    stationaryAssetTypeFieldDisabled,
}) {

    const sortedStationaryAssets = Array.isArray(stationaryAssets)
        ? stationaryAssets.sort((a, b) => {
            if (a.sequence === b.sequence) {
                return a.subsequence - b.subsequence;
            }
            return a.sequence - b.sequence;
        })
        : [];

    return (
        <div className="form-control">
            <label className="label font-bold sm:hidden">
                <span className="label-text">
                    Stationary Asset
                </span>
            </label>
            <div className="flex gap-4 items-center">
                <div className="hidden w-32 sm:block">
                    <label className="font-bold">
                        {" "}
                        <span className="label-text">
                            Stationary Asset
                        </span>
                    </label>
                </div>
                <div className="join w-full sm:w-auto">
                    <div className="join-item w-6/12 sm:w-60">
                        <SingleSelect
                            isDisabled={stationaryAssets.isLoading || stationaryAssetTypeFieldDisabled}
                            isLoading={stationaryAssets.isLoading}
                            placeholder="Type"
                            value={interdependentFields.stationary_asset_type}
                            handleOnChange={(stationary_asset_type) => {
                                if (!stationary_asset_type) {
                                    handleInterdependentFieldChange("stationary_asset", null);
                                }
                                handleInterdependentFieldChange("stationary_asset_type", stationary_asset_type)
                            }}
                            options={stationaryAssetTypes.map((stationaryAssetType) => {
                                return { value: stationaryAssetType, label: stationaryAssetType?.charAt(0)?.toUpperCase() + stationaryAssetType?.slice(1)?.toLowerCase() };
                            })}
                        />
                    </div>
                    <div className="join-item w-6/12 sm:w-60">
                        <SingleSelect
                            isDisabled={stationaryAssets.isLoading || isDisabled}
                            isLoading={stationaryAssets.isLoading}
                            placeholder="Pick one"
                            value={interdependentFields.stationary_asset}
                            handleOnChange={(stationary_asset) => handleInterdependentFieldChange("stationary_asset", stationary_asset)}
                            useFormatGroupLabel={true}
                            options={sortedStationaryAssets.map((stationaryAsset) => {
                                const options = stationaryAsset?.assets?.map((asset) => {
                                    return { value: asset.id, label: asset.name, location: asset?.location, location_id: asset?.location_id };
                                });
                                return {
                                    label: stationaryAsset.location_name,
                                    options: options,
                                };
                            })}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}