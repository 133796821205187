import { useEffect, useState, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import useFetchFunctions from "../../../../services/trips";
import Checkbox from "../../../Checkbox";
import SingleSelect from "../../../SingleSelect";
import NumberField from "../../../NumberField";
import TextArea from "../../../TextArea";
import DynamicFormFields from "../DynamicFormFields";
import WaiverStatusForm from "../WaiverStatusForm";
import { OccurrenceViaContext } from "../../../../utils/Contexts";
import InterdependentDelayFormFields from "../../../interdependent-form-fields/trip-occurrence-form/InterdependentDelayFormFields";
import EquipmentSearchField from "../../../interdependent-form-fields/trip-occurrence-form/EquipmentSearchField";

export default function NewOccurrenceForm({
  //form type props
  occurrenceVia,
  occurrenceDrawer,
  forceShowLinearAssets,
  //data props
  trip,
  service_date,
  linearAssets,
  stationaryAssets,
  responsibleParties,
  selectedOperatorVehicleCarrierCodes,
  operator_short_name,
  operatorOccurrenceCauses,
  //form field generators
  occurrenceCauseFields,
  setOccurrenceCauseFields,
  operatorTripOccurrenceFields,
  //form states
  tripOccurrencePayload,
  occurrenceCauseValuesPayload,
  operatorTripOccurrenceFieldsPayload,
  //form setters
  handleFieldChange,
  setTripOccurrencePayload,
  handleSetOccurrenceCauseValues,
  handleSetOperatorTripOccurrenceFieldValues,
  resetOccurrenceCauseValues,
  //vehicle search
  searchingVehicle,
  handleVehicleLookUp,
  vehicleSearchPayload,
  setVehicleSearchPayload,
  //for edit mode
  isEditMode,
  originalValues,
  loadingEditState,
  //new
  interdependentFields,
  handleInterdependentFieldChange,
  resetInterdependentFields
}) {
  const { occurrenceViaContext, setOccurrenceViaContext } = useContext(OccurrenceViaContext);

  const [showDelayMinutes, setShowDelayMinutes] = useState(false);
  const { fetchLocationsByLinearAsset } = useFetchFunctions();

  const foundLinearAsset = linearAssets?.data?.find((linear_asset) =>
    linear_asset.name === interdependentFields?.linear_asset?.name
  );
  const { data: locationsByLinearAsset, isLoading: locationsByLinearAssetIsLoading } = useQuery({
    queryKey: ["locations_by_linear_asset", interdependentFields?.linear_asset?.id ?? foundLinearAsset?.id],
    queryFn: () => fetchLocationsByLinearAsset(interdependentFields?.linear_asset?.id ?? foundLinearAsset?.id),
    staleTime: 0,
    enabled: !!occurrenceDrawer && (occurrenceVia?.action !== "originate" || occurrenceVia?.action !== "terminate") && (!!interdependentFields?.linear_asset?.id || !!foundLinearAsset?.id),
  });

  useEffect(() => {
    if (
      occurrenceVia?.action === "originate" ||
      occurrenceVia?.action === "terminate" ||
      occurrenceViaContext?.action === "cancel"
    ) {
      setShowDelayMinutes(false);
    } else {
      setShowDelayMinutes(true);
    }
  }, [occurrenceVia]);

  useEffect(() => {
    if (occurrenceVia?.action === "originate" || occurrenceVia?.action === "terminate") {
      handleInterdependentFieldChange("location", {
        value: occurrenceVia?.trip_location?.location?.id,
        label: occurrenceVia?.trip_location?.location?.name,
        sequence: occurrenceVia?.trip_location?.sequence,
      });
    }
  }, [occurrenceVia]);

  return (
    <div>
      <div className="form-control p-2 border border-[#d3d4d7] rounded">
        <label className="label gap-2 justify-start cursor-pointer">
          <Checkbox
            checked={tripOccurrencePayload.primary}
            value={tripOccurrencePayload.primary}
            handleOnChange={(event) => handleFieldChange("primary", event)}
          />
          <span className="label-text">This is a primary occurrence</span>
        </label>
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">
            Occurrence Cause <span className="text-error">*</span>
          </span>
        </label>
        <SingleSelect
          isDisabled={operatorOccurrenceCauses.isLoading}
          isLoading={operatorOccurrenceCauses.isLoading}
          placeholder="Pick one"
          useFormatGroupLabel={true}
          value={tripOccurrencePayload.operator_trip_occurrence_cause_id}
          handleOnChange={(operator_trip_occurrence_cause_id) => {
            if (!operator_trip_occurrence_cause_id) {
              resetOccurrenceCauseValues();
              resetInterdependentFields();
              setOccurrenceCauseFields(
                operator_trip_occurrence_cause_id?.value?.occurrence_cause_fields
              );
              handleFieldChange("operator_trip_occurrence_cause_id", null);
            } else {
              resetOccurrenceCauseValues();
              resetInterdependentFields();
              setOccurrenceCauseFields(
                operator_trip_occurrence_cause_id?.value?.occurrence_cause_fields
              );
              handleFieldChange("operator_trip_occurrence_cause_id", {
                value: operator_trip_occurrence_cause_id?.value?.occurrence_cause,
                label: operator_trip_occurrence_cause_id?.label,
              });
            }
          }}
          options={
            Array.isArray(operatorOccurrenceCauses?.data) &&
            Object.values(
              operatorOccurrenceCauses?.data?.reduce((acc, item) => {
                const type = item.occurrence_cause.type;
                if (!acc[type]) {
                  acc[type] = {
                    label: type,
                    options: [],
                  };
                }
                acc[type].options.push({
                  value: item,
                  label: item.occurrence_cause.name,
                });
                return acc;
              }, {})
            )
          }
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">
            Responsible Party <span className="text-error">*</span>
          </span>
        </label>
        <SingleSelect
          isDisabled={responsibleParties.isLoading}
          isLoading={responsibleParties.isLoading}
          placeholder="Pick one"
          value={tripOccurrencePayload.responsible_party_id}
          handleOnChange={(responsible_party_id) =>
            handleFieldChange("responsible_party_id", responsible_party_id)
          }
          options={
            responsibleParties?.data &&
            Array.isArray(responsibleParties?.data) &&
            responsibleParties?.data?.map((party) => {
              return { value: party.id, label: party.name };
            })
          }
        />
      </div>

      <InterdependentDelayFormFields
        tripLocations={trip?.trip_locations}
        locationsByLinearAsset={locationsByLinearAsset}
        linearAssets={linearAssets}
        stationaryAssets={stationaryAssets}
        selectedOperator={operator_short_name}
        occurrenceCause={tripOccurrencePayload?.operator_trip_occurrence_cause_id?.value}
        //new
        interdependentFields={interdependentFields}
        handleInterdependentFieldChange={handleInterdependentFieldChange}
      />

      {tripOccurrencePayload?.operator_trip_occurrence_cause_id?.value?.vehicle === "REQUIRED" || tripOccurrencePayload?.operator_trip_occurrence_cause_id?.value?.vehicle === "OPTIONAL" &&
        <EquipmentSearchField
          vehicleSearchPayload={vehicleSearchPayload}
          setVehicleSearchPayload={setVehicleSearchPayload}
          searchingVehicle={searchingVehicle}
          handleVehicleLookUp={handleVehicleLookUp}
          occurrencePayload={tripOccurrencePayload}
          setOccurrencePayload={setTripOccurrencePayload}
          selectedOperator={operator_short_name}
          trip={trip}
          handleFieldChange={handleFieldChange}
          selectedOperatorVehicleCarrierCodes={selectedOperatorVehicleCarrierCodes}
        />
      }

      {/* occurrence cause fields linked from occurrence causes */}
      <DynamicFormFields
        fields={occurrenceCauseFields}
        payload={occurrenceCauseValuesPayload}
        handleFieldChange={handleSetOccurrenceCauseValues}
      />

      <hr className="my-3" />

      {showDelayMinutes && (
        <div className="form-control max-w-20">
          <label className="label">
            <span className="label-text">
              Delay Minutes <span className="text-error">*</span>
            </span>
          </label>
          <NumberField
            value={tripOccurrencePayload.delay_minutes}
            handleOnChange={(event) => {
              if (event.target.value < 0) {
                return;
              } else {
                handleFieldChange(
                  "delay_minutes",
                  Number(event.target.value)
                );
              }
            }
            }
          />
        </div>
      )}

      {/* dynamic operator trip occurrence fields */}
      <DynamicFormFields
        fields={operatorTripOccurrenceFields?.data?.map((field) => {
          field.trip_occurrence_field.can_edit = field.can_edit;
          return field?.trip_occurrence_field;
        })}
        payload={operatorTripOccurrenceFieldsPayload}
        handleFieldChange={handleSetOperatorTripOccurrenceFieldValues}
      />

      {/* WAIVER STATUS */}

      <WaiverStatusForm
        isEditMode={isEditMode}
        originalValues={originalValues}
        payload={tripOccurrencePayload}
        setPayload={setTripOccurrencePayload}
        handleFieldChange={handleFieldChange}
      />

      <div className="form-control">
        <label className="label">
          <span className="label-text">
            Comments
            <span className="text-error"> *</span>
          </span>
        </label>
        <TextArea
          value={tripOccurrencePayload.comments}
          handleOnChange={(event) =>
            handleFieldChange("comments", event.target.value)
          }
        />
      </div>
    </div>
  );
}
