import Select, {
  type DropdownIndicatorProps,
  ClearIndicatorProps,
  components,
  OptionProps,
  GroupBase,
  GroupProps,
} from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faTimes } from "@fortawesome/pro-regular-svg-icons";

const FormatGroupLabel = (data, linkedOccurrenceDropdown) => {
  const labelStyle = linkedOccurrenceDropdown
    ? "text-[#565656] normal-case text-sm font-normal"
    : "";

  const activeStyles = {
    WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
  };

  if (!data?.label) {
    return null;
  }

  if (data.value) {
    return (
      <div
        className="flex justify-between hover:bg-[#EBF1F4] focus:bg-[#EBF1F4] w-full p-2"
        onClick={() => data.onClick(data.value)}
        style={activeStyles}
      >
        <div className={`${labelStyle}`}>{data?.label}</div>
        <div>{data?.options[0] === "" ? "0" : data?.options?.length}</div>
      </div>
    );
  }

  return (
    <div className="flex justify-between w-full p-2">
      <div>{data?.label}</div>
      <div>{data?.options?.length}</div>
    </div>
  );
};

const CustomOption = (props: OptionProps<any>) => {
  if (!props.label) {
    return null;
  } else {
    return (
      <components.Option {...props}>
        <li>{props.data.label}</li>
      </components.Option>
    );
  }
};

const CustomGroup = (props: GroupProps<any>) => {
  return (
    <components.Group {...props}>
      <ul className="menu not-prose p-0">
        <li>
          <ul>{props.children}</ul>
        </li>
      </ul>
    </components.Group>
  );
};

const DropDownIcon = () => {
  return <FontAwesomeIcon icon={faChevronDown} />;
};

const ClearIcon = () => {
  return <FontAwesomeIcon icon={faTimes} />;
};

const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropDownIcon />
    </components.DropdownIndicator>
  );
};

const ClearIndicator: React.FC<ClearIndicatorProps> = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <ClearIcon />
    </components.ClearIndicator>
  );
};

const colourStyles = {
  control: (styles, { isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled ? "#f1f0ef" : "white",
    minHeight: "2.5rem",
    fontSize: "0.875rem",
    ":hover": {},
    ":focus": {
      borderColor: "var(--fallback-bc, oklch(var(--bc) / 0.2))",
      outlineStyle: "solid !important",
      outlineWidth: "2px !important",
      outlineOffset: "2px !important",
      outlineColor: "#00AAC3 !important",
      boxShadow: "none",
    },
    ":focus-within": {
      borderColor: "var(--fallback-bc, oklch(var(--bc) / 0.2))",
      outlineStyle: "solid !important",
      outlineWidth: "2px !important",
      outlineOffset: "2px !important",
      outlineColor: "#00AAC3 !important",
      boxShadow: "none",
    },
    "@media only screen and (max-width: 1023px)": {
      ...styles["@media only screen and (max-width: 1023px)"],
      minHeight: "2rem",
      maxHeight: "2rem",
      fontSize: "0.875rem",
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    "@media only screen and (max-width: 1023px)": {
      ...styles["@media only screen and (max-width: 1023px)"],
      paddingTop: "2px",
      paddingBottom: "2px",
    },
  }),
  placeholder: (styles, { isDisabled }) => ({
    ...styles,
    color: isDisabled ? "#808080" : "black",
  }),
  input: (styles) => ({
    ...styles,
    "@media only screen and (max-width: 1023px)": {
      ...styles["@media only screen and (max-width: 1023px)"],
      paddingTop: "2px",
      paddingBottom: "2px",
    },
  }),
  dropdownIndicator: (styles, { isDisabled, hasValue }) => ({
    ...styles,
    color: isDisabled ? "#808080" : "black",
    "@media only screen and (max-width: 1023px)": {
      ...styles["@media only screen and (max-width: 1023px)"],
      paddingTop: "2px",
      paddingBottom: "2px",
    },
    display: hasValue ? "none" : "block",
  }),
  clearIndicator: (styles) => ({
    ...styles,
    color: "black",
    "@media only screen and (max-width: 1023px)": {
      ...styles["@media only screen and (max-width: 1023px)"],
      paddingTop: "2px",
      paddingBottom: "2px",
    },
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    width: "0",
  }),
  menuList: (styles) => ({
    ...styles,
    background: "white",
    border: "1px solid #000",
    borderRadius: "4px",
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    zIndex: 1,
  }),
  menu: (base) => ({
    ...base,
    zIndex: 100,
    boxShadow: "none",
    width: "max-content",
    // position: "absolute",
    // right: "0",
    // transform: "translateX(+10%)",
    // overflow: "visible",
  }),
  group: (base) => ({
    ...base,
    padding: 0,
    margin: 0,
  }),
  groupHeading: (base) => ({
    ...base,
    padding: 0,
    margin: 0,
  }),
};

export default function SingleSelect<T = string>({
  options,
  handleOnChange,
  isLoading,
  placeholder,
  isDisabled,
  value,
  useFormatGroupLabel,
  linkedOccurrenceDropdown,
}: {
  options: Option<T>[];
  handleOnChange: (value: T) => void;
  isLoading?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  value: Option<T>;
  useFormatGroupLabel?: boolean;
  linkedOccurrenceDropdown?: boolean;
}) {
  const customComponents = useFormatGroupLabel
    ? { Group: CustomGroup, Option: CustomOption, DropdownIndicator, ClearIndicator }
    : { DropdownIndicator, ClearIndicator };

  return (
    <Select
      styles={colourStyles}
      components={customComponents}
      value={value || ""}
      className="my-select"
      classNamePrefix="my-select"
      isClearable={true}
      isDisabled={isDisabled}
      placeholder={placeholder}
      isLoading={isLoading}
      options={options ? options : []}
      // menuPosition="fixed"
      // menuPortalTarget={document.body}
      formatGroupLabel={
        useFormatGroupLabel
          ? (data) => FormatGroupLabel(data, linkedOccurrenceDropdown)
          : null
      }
      onChange={(event, action) => handleOnChange(event, action)}
    />
  );
}
