import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import SingleSelect from "./SingleSelect";

const RESULTS_PER_PAGE = ["50", "100", "150"];

export default function Pagination({ searchResults, handleChangePage }) {
  const { page_number, page_size, total_rows, results } = searchResults;
  const totalPages = Math.ceil(total_rows / page_size);
  const buttonCount = Math.min(5, totalPages);
  const calculateStartingPageCount = () => {
    if (page_number === 1) {
      return 1;
    } else {
      return page_size * page_number - page_size;
    }
  };
  const calculateEndPageCount = () => {
    if (total_rows < page_size) {
      return total_rows;
    } else if (page_number === 1) {
      return page_size;
    } else if (page_number === totalPages) {
      return total_rows;
    } else {
      return page_size * page_number;
    }
  };

  const startingPageCount = calculateStartingPageCount();
  const endPageCount = calculateEndPageCount();

  const MapPageSelections = () => {
    let startPage;
    let endPage;
    if (totalPages <= buttonCount) {
      startPage = 1;
      endPage = totalPages;
    } else {
      const halfButtonCount = Math.floor(buttonCount / 2);
      const distanceFromCenter = Math.min(
        halfButtonCount,
        Math.max(page_number - 1, totalPages - page_number)
      );

      startPage = Math.max(
        1,
        Math.min(page_number - distanceFromCenter, totalPages - buttonCount + 1)
      );

      endPage = Math.min(totalPages, startPage + buttonCount - 1);
    }

    const buttons = [];

    if (startPage > 1) {
      buttons.push(
        <button
          className={`join-item btn ${page_number === 1 ? "btn-primary" : ""}`}
          onClick={() => handleChangePage(1, page_size)}
        >
          1
        </button>
      );
      buttons.push(
        <button key="..." className="join-item btn pointer-events-none">
          ...
        </button>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => handleChangePage(i, page_size)}
          className={`join-item btn ${i === page_number ? "btn-primary" : ""}`}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPages - 1) {
      buttons.push(
        <button key="..." className="join-item btn pointer-events-none">
          ...
        </button>
      );
    }

    if (endPage < totalPages) {
      buttons.push(
        <button
          key={totalPages}
          className={`join-item btn ${page_number === totalPages ? "btn-primary" : ""}`}
          onClick={() => handleChangePage(totalPages, page_size)}
        >
          {totalPages}
        </button>
      );
    }

    return buttons;
  };

  return (
    <div className="card-footer hidden md:block lg:flex justify-between py-3 px-4 rounded-b border-t">
      <div className="flex gap-2">
        <button
          className="btn"
          disabled={page_number === 1}
          onClick={() => handleChangePage(page_number - 1, page_size)}
        >
          <FontAwesomeIcon icon={faChevronLeft} /> Prev
        </button>
        <div className="join">
          <MapPageSelections />
        </div>
        <button
          className="btn"
          disabled={page_number === totalPages}
          onClick={() => handleChangePage(page_number + 1, page_size)}
        >
          Next <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>

      <div className="flex gap-7 items-center pt-3 lg:pt-0">
        {startingPageCount}-{endPageCount} of {total_rows}
        <div className="flex gap-3 items-center">
          Results per page{" "}
          <SingleSelect
            value={total_rows}
            placeholder={page_size}
            handleOnChange={(resultsPerPage) => {
              handleChangePage(page_number, resultsPerPage.value);
            }}
            options={RESULTS_PER_PAGE.map((option) => {
              return { value: option, label: option };
            })}
          />
        </div>
      </div>
    </div>
  );
}
