import SingleSelect from "../../SingleSelect";

export default function LocationField({
    interdependentFields,
    handleOnChange,
    locations,
    isDisabled,
    isRequired
}) {
    return (
        <div className="form-control">
            <label className="label">
                <span className="label-text">
                    Location
                    {isRequired && <span className="text-error"> *</span>}
                </span>
            </label>
            <SingleSelect
                placeholder="Pick one"
                isLoading={locations?.isLoading}
                isDisabled={locations?.isLoading || isDisabled}
                value={interdependentFields.location}
                handleOnChange={(location) => handleOnChange(location)}
                options={locations?.map((location) => {
                    return {
                        label: location.name,
                        value: location.id,
                    };
                })}
            />
        </div>
    )
}
