import { createContext } from "react";

export const TripContext = createContext(null);

export const SearchResultsContext = createContext(null);

export const NavigationContext = createContext(null);

export const UserContext = createContext(null);

export const OccurrenceViaContext = createContext(null);
