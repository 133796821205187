import LoadingSpinner from "../../../icons/LoadingSpinner";
import TextField from "../../TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrain } from "@fortawesome/pro-regular-svg-icons";
import SingleSelect from "../../SingleSelect";
import { useEffect, useRef } from "react";

export default function EquipmentSearchField({
    occurrencePayload,
    setOccurrencePayload,
    handleVehicleLookUp,
    searchingVehicle,
    setVehicleSearchPayload,
    vehicleSearchPayload,
    selectedOperator,
}) {
    const debounceTimeout = useRef(null);
    const handleOnChangeWrapper = (event) => {
        const value = event.target.value;
        setVehicleSearchPayload((prevState) => ({
            ...prevState,
            serial_number: value,
        }));

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            if (value.trim()) {
                handleVehicleLookUp(value);
            }
        }, 3000);
    };

    const handleBlur = () => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        if (vehicleSearchPayload.serial_number.trim()) {
            handleVehicleLookUp();
        }
    }

    const vehicleCarrierCodes = selectedOperator?.value?.vehicle_carrier_codes || selectedOperator?.vehicle_carrier_codes || [];

    const updateVehicleSearchPayload = (resetVehicle = false) => {
        const newPayload = {
            ...vehicleSearchPayload,
            carrier_code: "",
            serial_number: "",
        };

        if (vehicleCarrierCodes?.length === 1) {
            newPayload.carrier_code = {
                label: vehicleCarrierCodes[0],
                value: vehicleCarrierCodes[0],
            };
        }

        setVehicleSearchPayload(newPayload);

        if (resetVehicle) {
            // handleFieldChange("vehicle", "");
            setOccurrencePayload((prevState) => ({ ...prevState, vehicle: "" }));
        }
    };

    useEffect(() => {
        if (!vehicleCarrierCodes) {
            updateVehicleSearchPayload(true);
        } else {
            updateVehicleSearchPayload();
        }
    }, [selectedOperator]);

    return (
        <div className="form-control">
            <>
                <label className="label font-bold sm:hidden">
                    <span className="label-text">
                        Vehicle
                    </span>
                </label>
                <div className="flex gap-4 items-center">
                    <div className="hidden w-32 sm:block">
                        <label className="font-bold">
                            {" "}
                            <span className="label-text">
                                Vehicle
                            </span>
                        </label>
                    </div>
                    {occurrencePayload?.vehicle && occurrencePayload?.vehicle !== "" && (
                        <button
                            className="btn btn-sm btn-outline btn-error"
                            onClick={() => {
                                updateVehicleSearchPayload(true);
                            }}
                        >
                            Unselect Vehicle
                        </button>
                    )}
                    <>
                        {occurrencePayload?.vehicle && occurrencePayload?.vehicle !== "" ? (
                            <div className="card card-compact p-3 border border-base-300 bg-[#F2F5F7]">
                                <div className="flex justify-between gap-10">
                                    <div className="flex gap-5">
                                        <div>
                                            <div>
                                                <FontAwesomeIcon icon={faTrain} />{" "}
                                                {occurrencePayload?.vehicle?.carrier_code}{" "}
                                                {occurrencePayload?.vehicle?.serial_number}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="join w-full sm:w-auto">
                                <div className="join-item w-6/12 sm:w-40">
                                    <SingleSelect
                                        isDisabled={searchingVehicle || !selectedOperator || vehicleCarrierCodes?.length <= 1}
                                        placeholder={"Carrier Code"}
                                        value={vehicleSearchPayload?.carrier_code}
                                        options={vehicleCarrierCodes?.map((carrier_code) => ({
                                            label: carrier_code,
                                            value: carrier_code,
                                        }))}
                                        handleOnChange={(selectedOption) =>
                                            setVehicleSearchPayload({
                                                ...vehicleSearchPayload,
                                                carrier_code: selectedOption,
                                            })
                                        }
                                    />
                                </div>
                                <div className="join-item w-6/12 sm:w-40 relative">
                                    <TextField
                                        disabled={searchingVehicle || !selectedOperator}
                                        className={"w-full"}
                                        placeholder={"Serial #"}
                                        value={vehicleSearchPayload?.serial_number}
                                        onBlur={() => handleBlur()}
                                        handleOnChange={(event) => handleOnChangeWrapper(event)}
                                    />
                                    {searchingVehicle && <div className="absolute start-36 md:start-32 bottom-0 md:bottom-0.5">
                                        <LoadingSpinner />
                                    </div>}
                                </div>
                            </div>
                        )}
                    </>
                </div>
            </>
        </div>
    )
}

