import SingleSelect from "../../SingleSelect";

export default function LocationField({
    interdependentFields,
    handleOnChange,
    locations,
    isDisabled,
}) {

    return (
        <div className="form-control">

            <div className="flex gap-4 items-center">
                <div className="hidden w-32 sm:block">
                    <label className="font-bold">
                        {" "}
                        <span className="label-text">
                            Location
                        </span>
                    </label>
                </div>
                <div className="w-full  sm:w-60">
                    <label className="label font-bold sm:hidden">
                        <span className="label-text">
                            Location
                        </span>
                    </label>
                    <SingleSelect
                        placeholder="Pick one"
                        isLoading={locations?.isLoading}
                        isDisabled={locations?.isLoading || isDisabled}
                        value={interdependentFields.location}
                        handleOnChange={(location) => handleOnChange(location)}
                        options={locations?.map((location) => {
                            return {
                                label: location.name,
                                value: location.id,
                            };
                        })}
                    />
                </div>
            </div>

        </div>
    )
}
