import TextField from "../../TextField";
import Checkbox from "../../Checkbox";
import DateTimePicker from "../../DateTimePicker";
import NumberField from "../../NumberField";
import SingleSelect from "../../SingleSelect";
import MultiSelect from "../../MultiSelect";
import YesNoCheckbox from "../../YesNoCheckbox";

const TextFieldComponent = ({ field, payload, handleFieldChange, labelOnSide, disabled }) => (
  <>
    {labelOnSide ? (
      <div>
        <div className="flex gap-4 items-center">
          <div className="w-32">
            <label className="font-bold">{field.label}</label>
          </div>
          <div className="w-60">
            <TextField
              disabled={disabled}
              className={"w-full min-w-0"}
              value={payload[field.name] ?? ""}
              placeholder={field.label}
              handleOnChange={(event) =>
                handleFieldChange(field.name, event.target.value, field.category)
              }
            />
          </div>
        </div>
      </div>
    ) : (
      <div className="form-control">
        <label className="label">
          <span className="label-text">{field.label}</span>
        </label>
        <TextField
          disabled={disabled}
          className={"flex items-center gap-2"}
          value={payload[field.name] ?? ""}
          placeholder={field.label}
          handleOnChange={(event) =>
            handleFieldChange(field.name, event.target.value, field.category)
          }
        />
      </div>
    )}
  </>
);

const SingleSelectComponent = ({
  field,
  payload,
  handleFieldChange,
  labelOnSide,
  disabled,
}) => (
  <>
    {labelOnSide ? (
      <div>
        <div className="flex gap-4 items-center">
          <div className="w-32">
            <label className="font-bold">{field.label}</label>
          </div>
          <div className="w-60">
            <MultiSelect
              isDisabled={disabled}
              value={payload[field.name]}
              options={field.lookup_list_items.map((option) => {
                return { value: option, label: option };
              })}
              handleOnChange={(option) =>
                handleFieldChange(field.name, option, field.category)
              }
            />
          </div>
        </div>
      </div>
    ) : (
      <div className="form-control">
        <label className="label">
          <span className="label-text">{field.label}</span>
        </label>
        <SingleSelect
          isDisabled={disabled}
          value={payload[field.name]}
          options={field.lookup_list_items.map((option) => {
            return { value: option, label: option };
          })}
          handleOnChange={(option) =>
            handleFieldChange(field.name, option, field.category)
          }
        />
      </div>
    )}
  </>
);

const CheckboxComponent = ({ field, payload, handleFieldChange, labelOnSide, disabled }) => (
  <>
    {labelOnSide ? (
      <div>
        <YesNoCheckbox
          name={field.label}
          handleOnChange={(event) =>
            handleFieldChange(field.name, event, field.category)
          }
          value={payload[field.name]}
        />
      </div>
    ) : (
      <div className="form-control self-center">
        <label className="label gap-2 justify-start cursor-pointer">
          <Checkbox
            disabled={disabled}
            string={true}
            value={payload[field.name]}
            checked={payload[field.name]}
            handleOnChange={(event) =>
              handleFieldChange(field.name, event, field.category)
            }
          />
          <span className="label-text"> {field.label}</span>
        </label>
      </div>
    )}
  </>
);

const DateTimePickerComponent = ({
  field,
  payload,
  handleFieldChange,
  labelOnSide,
  disabled,
}) => (
  <>
    {labelOnSide ? (
      <div>
        <div className="flex gap-4 items-center">
          <div className="w-32">
            <label className="font-bold">{field.label}</label>
          </div>
          <div className="w-60 min-w-0">
            <DateTimePicker
              disabled={disabled}
              value={payload[field.name]}
              handleOnChange={(date) =>
                handleFieldChange(field.name, date, field.category)
              }
              className="input input-bordered input-sm lg:input-md w-full"
            />
          </div>
        </div>
      </div>
    ) : (
      <div className="form-control">
        <label className="label">
          <span className="label-text truncate">{field.label}</span>
        </label>
        <DateTimePicker
          disabled={disabled}
          value={payload[field.name]}
          handleOnChange={(date) =>
            handleFieldChange(field.name, date, field.category)
          }
          className="input input-bordered input-sm lg:input-md w-full"
        />
      </div>
    )}
  </>
);

const NumberFieldComponent = ({
  field,
  payload,
  handleFieldChange,
  labelOnSide,
  disabled,
}) => (
  <>
    {labelOnSide ? (
      <div>
        <div className="flex gap-4 items-center">
          <div className="w-32">
            <label className="font-bold">{field.label}</label>
          </div>
          <div className="w-60">
            <NumberField
              disabled={disabled}
              value={payload[field.name]}
              handleOnChange={(event) =>
                handleFieldChange(field.name, event.target.value, field.category)
              }
            />
          </div>
        </div>
      </div>
    ) : (
      <div className="form-control">
        <label className="label">
          <span className="label-text">{field.label}</span>
        </label>
        <NumberField
          disabled={disabled}
          value={payload[field.name]}
          handleOnChange={(event) =>
            handleFieldChange(field.name, event.target.value, field.category)
          }
        />
      </div>
    )}
  </>
);

export default function DynamicFormFields({
  fields,
  payload,
  handleFieldChange,
  hideNumberFields,
  labelOnSide,
}) {
  const groupedFields = fields?.reduce(
    (acc, field) => {
      if (field.type === "BOOLEAN") {
        acc.checkboxes.push(field);
      } else if (field.type === "TEXT" && field.lookup_list_items?.length > 0) {
        acc.singleSelects.push(field);
      } else if (field.type === "TEXT") {
        acc.textFields.push(field);
      } else if (field.type === "DATETIME") {
        acc.dateTimePickers.push(field);
      } else if (field.type === "INTEGER") {
        acc.numberFields.push(field);
      }
      return acc;
    },
    {
      checkboxes: [],
      singleSelects: [],
      textFields: [],
      dateTimePickers: [],
      numberFields: [],
    }
  );

  return (
    <>
      {labelOnSide ? (
        <>
          {!!groupedFields?.checkboxes?.length && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 items-center">
              {groupedFields?.checkboxes?.map((field) => (
                <CheckboxComponent
                  disabled={field.can_edit === false}
                  key={field.name}
                  field={field}
                  payload={payload}
                  handleFieldChange={handleFieldChange}
                  labelOnSide={labelOnSide}
                />
              ))}
            </div>
          )}
          {!!groupedFields?.singleSelects?.length && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 items-center">
              {groupedFields?.singleSelects?.map((field) => (
                <SingleSelectComponent
                  disabled={field.can_edit === false}
                  key={field.name}
                  field={field}
                  payload={payload}
                  handleFieldChange={handleFieldChange}
                  labelOnSide={labelOnSide}
                />
              ))}
            </div>
          )}
          {/* {!!groupedFields?.textFields?.length && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 items-center">
              {groupedFields?.textFields?.map((field) => (
                <TextFieldComponent
                  disabled={field.can_edit === false}
                  key={field.name}
                  field={field}
                  payload={payload}
                  handleFieldChange={handleFieldChange}
                  labelOnSide={labelOnSide}
                />
              ))}
            </div>
          )} */}
          {/* {!!groupedFields?.dateTimePickers?.length && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 items-center">
              {groupedFields?.dateTimePickers?.map((field) => (
                <DateTimePickerComponent
                  disabled={field.can_edit === false}
                  key={field.name}
                  field={field}
                  payload={payload}
                  handleFieldChange={handleFieldChange}
                  labelOnSide={labelOnSide}
                />
              ))}
            </div>
          )} */}
          {/* {!!groupedFields?.numberFields?.length && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 items-center">
              {groupedFields?.numberFields?.map((field) => {
                if (hideNumberFields) {
                  return null;
                }
                return (
                  <NumberFieldComponent
                    disabled={field.can_edit === false}
                    key={field.name}
                    field={field}
                    payload={payload}
                    handleFieldChange={handleFieldChange}
                    labelOnSide={labelOnSide}
                  />
                );
              })}
            </div>
          )} */}
        </>
      ) : (
        <>
          {groupedFields?.checkboxes?.map((field) => (
            <CheckboxComponent
              disabled={field.can_edit === false}
              key={field.name}
              field={field}
              payload={payload}
              handleFieldChange={handleFieldChange}
              labelOnSide={labelOnSide}
            />
          ))}
          {groupedFields?.singleSelects?.map((field) => (
            <SingleSelectComponent
              disabled={field.can_edit === false}
              key={field.name}
              field={field}
              payload={payload}
              handleFieldChange={handleFieldChange}
              labelOnSide={labelOnSide}
            />
          ))}
          {groupedFields?.textFields?.map((field) => (
            <TextFieldComponent
              disabled={field.can_edit === false}
              key={field.name}
              field={field}
              payload={payload}
              handleFieldChange={handleFieldChange}
              labelOnSide={labelOnSide}
            />
          ))}
          {groupedFields?.dateTimePickers?.map((field) => (
            <DateTimePickerComponent
              disabled={field.can_edit === false}
              key={field.name}
              field={field}
              payload={payload}
              handleFieldChange={handleFieldChange}
              labelOnSide={labelOnSide}
            />
          ))}
          {groupedFields?.numberFields?.map((field) => {
            if (hideNumberFields) {
              return null;
            }
            return (
              <NumberFieldComponent
                disabled={field.can_edit === false}
                key={field.name}
                field={field}
                payload={payload}
                handleFieldChange={handleFieldChange}
                labelOnSide={labelOnSide}
              />
            );
          })}
        </>
      )}
    </>
  );
}
