import { apiUrl, createAuthHelpers } from "./api";
import { useMsal } from "@azure/msal-react";
import toast from "react-hot-toast";

export const useFetchFunctions = () => {
    const { instance: msalInstance } = useMsal();
    const { authHeaders } = createAuthHelpers(msalInstance);

    const fetchData = async (url, options = {}) => {
        const headers = await authHeaders();
        const response = await fetch(url, { headers, ...options });

        if (!response.ok) {
            let errorDetail = `Error: ${response.statusText}`;
            try {
                const errorResponse = await response.json();
                errorDetail =
                    typeof errorResponse === "string"
                        ? errorResponse
                        : errorResponse?.detail || errorDetail;
            } catch {
                console.error("Error parsing error response.");
            }
            toast.error(errorDetail);
            throw new Error(errorDetail);
        }

        if (response.status === 204) {
            return null;
        }

        return response.json();
    };

    const buildUrl = (path, params = {}) => {
        const queryString = new URLSearchParams(params).toString();
        return `${apiUrl}/${path}${queryString ? `?${queryString}` : ""}`;
    };

    // Trip APIs
    const fetchTripFields = () => fetchData(buildUrl("tripFields"));
    const extendTrip = (operator, date, trip, payload) =>
        fetchData(
            buildUrl(
                `operator/${operator}/operator_service_day/${date}/trip/${trip}/extend`
            ),
            {
                method: "POST",
                body: JSON.stringify(payload),
            }
        );

    const resetTrip = (operator, date, trip) =>
        fetchData(
            buildUrl(
                `operator/${operator}/operator_service_day/${date}/trip/${trip}/reset`
            ),
            {
                method: "POST",
            }
        );

    const postTrip = (operator, date, payload) =>
        fetchData(
            buildUrl(`operator/${operator}/operator_service_day/${date}/trips`),
            {
                method: "POST",
                body: JSON.stringify(payload),
            }
        );

    const editTripLocations = (operator, date, trip, payload) =>
        fetchData(
            buildUrl(
                `operator/${operator}/operator_service_day/${date}/trip/${trip}/trip_locations`
            ),
            {
                method: "PUT",
                body: JSON.stringify(payload),
            }
        );

    const originateTripLocation = (operator, date, trip, payload) =>
        fetchData(
            buildUrl(
                `operator/${operator}/operator_service_day/${date}/trip/${trip}/originate`
            ),
            {
                method: "PUT",
                body: JSON.stringify(payload),
            }
        );

    const terminateTripLocation = (operator, date, trip, payload) =>
        fetchData(
            buildUrl(
                `operator/${operator}/operator_service_day/${date}/trip/${trip}/terminate`
            ),
            {
                method: "PUT",
                body: JSON.stringify(payload),
            }
        );

    const fetchAvailableReroutes = (operator, date, trip) =>
        fetchData(
            buildUrl(
                `operator/${operator}/operator_service_day/${date}/trip/${trip}/available_reroutes`
            )
        );

    const postRerouteTrip = (operator, date, trip, payload) =>
        fetchData(
            buildUrl(
                `operator/${operator}/operator_service_day/${date}/trip/${trip}/reroute`
            ),
            {
                method: "POST",
                body: JSON.stringify(payload),
            }
        );

    const fetchTrip = (operator, date, trip) =>
        fetchData(
            buildUrl(
                `operator/${operator}/operator_service_day/${date}/trip/${trip}`
            )
        );

    const fetchTrips = (operator, payload) =>
        fetchData(buildUrl(`operator/${operator}/trip_search`), {
            method: "POST",
            body: JSON.stringify(payload),
        });

    const cancelTrip = (operator, date, trip) =>
        fetchData(
            buildUrl(
                `operator/${operator}/operator_service_day/${date}/trip/${trip}/cancel`
            ),
            { method: "PUT" }
        );

    const editTripValues = (operator, date, trip, payload) =>
        fetchData(
            buildUrl(
                `operator/${operator}/operator_service_day/${date}/trip/${trip}/trip_values`
            ),
            {
                method: "PUT",
                body: JSON.stringify(payload),
            }
        );

    const signOffTrip = (operator, date, trip, payload) =>
        fetchData(
            buildUrl(
                `operator/${operator}/operator_service_day/${date}/trip/${trip}/signed_off`
            ),
            {
                method: "PATCH",
                body: JSON.stringify(payload),
            }
        );

    const fetchOperatorTripLocationFields = (operator) =>
        fetchData(buildUrl(`operator/${operator}/operator_trip_location_fields`));

    // Occurrence APIs
    const fetchOccurrences = (operator, date) =>
        fetchData(
            buildUrl(`operator/${operator}/operator_service_day/${date}/occurrences`)
        );

    const searchOccurrences = (operator, payload) =>
        fetchData(buildUrl(`operator/${operator}/occurrences_search`), {
            method: "POST",
            body: JSON.stringify(payload),
        });

    const editOccurrence = (operator, id, payload) =>
        fetchData(buildUrl(`operator/${operator}/occurrences/${id}`), {
            method: "PUT",
            body: JSON.stringify(payload),
        });

    const editTripOccurrence = (operator, date, trip, payload) =>
        fetchData(
            buildUrl(
                `operator/${operator}/operator_service_day/${date}/trip/${trip}/trip_occurrences`
            ),
            {
                method: "PUT",
                body: JSON.stringify(payload),
            }
        );

    const deleteTripOccurrence = (operator, date, trip, occurrence_id) =>
        fetchData(
            buildUrl(
                `operator/${operator}/operator_service_day/${date}/trip/${trip}/trip_occurrences/${occurrence_id}`
            ),
            { method: "DELETE" }
        );

    const editResidualTripOccurrence = (
        operator,
        occurrence_id,
        trip_occurrence_id,
        payload
    ) =>
        fetchData(
            buildUrl(
                `operator/${operator}/occurrences/${occurrence_id}/trip_occurrences/${trip_occurrence_id}`
            ),
            {
                method: "PUT",
                body: JSON.stringify(payload),
            }
        );

    const fetchTripOccurrence = (operator, date, trip, occurrence_id) =>
        fetchData(
            buildUrl(
                `operator/${operator}/operator_service_day/${date}/trip/${trip}/trip_occurrences/${occurrence_id}`
            )
        );

    const postOccurrence = (operator, payload) =>
        fetchData(buildUrl(`operator/${operator}/occurrences`), {
            method: "POST",
            body: JSON.stringify(payload),
        });

    const postTripOccurrence = (operator, date, trip, payload) =>
        fetchData(
            buildUrl(
                `operator/${operator}/operator_service_day/${date}/trip/${trip}/trip_occurrences`
            ),
            {
                method: "POST",
                body: JSON.stringify(payload),
            }
        );

    const searchLinkToExisting = (payload) =>
        fetchData(buildUrl("occurrences/link_to_existing_search"), {
            method: "POST",
            body: JSON.stringify(payload),
        });

    // Vehicle APIs
    const fetchVehicles = (operator) =>
        fetchData(buildUrl(`operator/${operator}/vehicles`));

    const assignVehicleFormation = (operator, date, trip, payload) =>
        fetchData(
            buildUrl(
                `operator/${operator}/operator_service_day/${date}/trip/${trip}/assigned_vehicle_formation`
            ),
            {
                method: "PATCH",
                body: JSON.stringify(payload),
            }
        );

    // Asset APIs
    const fetchStationaryAssets = (operator, date, trip, type) =>
        fetchData(
            buildUrl(
                `operator/${operator}/operator_service_day/${date}/trip/${trip}/stationary_assets`,
                { stationary_asset_type: type }
            )
        );

    const fetchLinearAssets = (operator, date, trip) =>
        fetchData(
            buildUrl(
                `operator/${operator}/operator_service_day/${date}/trip/${trip}/linear_assets`
            )
        );

    const fetchLocationsByTransportationMode = (mode) =>
        fetchData(buildUrl("locations", { transportation_mode: mode }));

    const fetchPaths = (operator, origin, destination) =>
        fetchData(
            buildUrl(`operator/${operator}/pathfinding`, { origin, destination })
        );

    const fetchAuditLog = (operator, date, trip) =>
        fetchData(
            buildUrl(
                `operator/${operator}/operator_service_day/${date}/trip/${trip}/auditlog`
            )
        );

    const fetchTripOccurrenceAuditLog = (operator, id) =>
        fetchData(buildUrl(`operator/${operator}/trip_occurrences/${id}/auditlog`));

    // Utility APIs
    const fetchOperators = () => fetchData(buildUrl("operators"));
    const fetchResponsibleParties = () => fetchData(buildUrl("responsible_parties"));
    const fetchScheduleTypes = () => fetchData(buildUrl("schedule_types"));

    const fetchOperatorTripFields = (operator) =>
        fetchData(buildUrl(`operator/${operator}/operator_trip_fields`));

    const fetchOperatorLocationFields = (operator) =>
        fetchData(buildUrl(`operator/${operator}/operator_trip_location_fields`));

    const fetchOperatorOccurrenceCauses = (operator) =>
        fetchData(buildUrl(`operator/${operator}/operator_occurrence_causes`));

    const fetchOperatorTripOccurrenceFields = (operator) =>
        fetchData(buildUrl(`operator/${operator}/operator_trip_occurrence_fields`));

    const fetchLinearAssetsByTripLocation = (operator, date, trip, sequence) =>
        fetchData(
            buildUrl(
                `operator/${operator}/operator_service_day/${date}/trip/${trip}/locations/${sequence}/linear_assets`
            )
        );

    const fetchLinearAssetsByTransportationMode = (mode) =>
        fetchData(buildUrl("linear_assets", { transportation_mode: mode }));

    const fetchStationaryAssetsByLinearAsset = (asset_id, type) =>
        fetchData(buildUrl(`linear_asset/${asset_id}/stationary_assets/${type}`));

    const fetchLocationsByLinearAsset = (asset_id) =>
        fetchData(buildUrl(`linear_asset/${asset_id}/locations`));

    const fetchVehicle = (operator, carrier_code, serial_number) =>
        fetchData(
            buildUrl(
                `operator/${operator}/vehicles/${carrier_code}/${serial_number}`
            )
        );

    const fetchOccurrence = (operator, occurrence_id) =>
        fetchData(buildUrl(`operator/${operator}/occurrences/${occurrence_id}`));

    const fetchVehicleFormations = (
        operator,
        carrier_code,
        serial_number,
        from_datetime,
        to_datetime
    ) =>
        fetchData(
            buildUrl(`operator/${operator}/vehicle_formations`, {
                carrier_code,
                serial_number,
                from_datetime,
                to_datetime,
            })
        );

    return {
        fetchTripFields,
        extendTrip,
        fetchAvailableReroutes,
        postRerouteTrip,
        fetchOperatorTripLocationFields,
        fetchTrip,
        fetchTrips,
        postTrip,
        resetTrip,
        cancelTrip,
        signOffTrip,
        editTripLocations,
        originateTripLocation,
        terminateTripLocation,
        fetchVehicleFormations,
        editTripValues,
        fetchOccurrences,
        searchOccurrences,
        editOccurrence,
        editTripOccurrence,
        editResidualTripOccurrence,
        fetchTripOccurrence,
        deleteTripOccurrence,
        postTripOccurrence,
        searchLinkToExisting,
        postOccurrence,
        fetchLinearAssetsByTransportationMode,
        fetchLinearAssetsByTripLocation,
        fetchStationaryAssetsByLinearAsset,
        fetchLocationsByLinearAsset,
        fetchVehicle,
        fetchOccurrence,
        fetchVehicles,
        assignVehicleFormation,
        fetchStationaryAssets,
        fetchLinearAssets,
        fetchLocationsByTransportationMode,
        fetchPaths,
        fetchAuditLog,
        fetchTripOccurrenceAuditLog,
        fetchOperators,
        fetchResponsibleParties,
        fetchScheduleTypes,
        fetchOperatorTripFields,
        fetchOperatorLocationFields,
        fetchOperatorOccurrenceCauses,
        fetchOperatorTripOccurrenceFields,
    };
};

export default useFetchFunctions;
