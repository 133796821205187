export const COLUMNS = [
  { name: "location", display_name: "Location", visible: true, can_edit: true, className: "md:sticky md:left-14 md:top-0 z-20 md:bg-white" },
  {
    name: "actual_is_stopping",
    display_name: "Served",
    visible: true,
    can_edit: true,
  },
  {
    name: "scheduled_arrival",
    display_name: "Scheduled Arrival",
    visible: true,
    can_edit: true,
  },
  {
    name: "scheduled_departure",
    display_name: "Scheduled Departure",
    visible: true,
    can_edit: true,
  },
  { name: "originate", display_name: "Originate", visible: true, can_edit: true, className: "hidden sm:table-cell" },
  { name: "terminate", display_name: "Terminate", visible: true, can_edit: true, className: "hidden sm:table-cell" },
];
