import { UserContext } from "../../../utils/Contexts";
import { useContext } from "react";
import { APPROVED, DENIED, REQUESTED, NOT_REQUESTED } from "../../../constants/constants";

export default function WaiverStatusForm({
  payload,
  isEditMode,
  originalValues,
  setPayload,
  handleFieldChange,
  formType,
}) {
  const { currentUserContext } = useContext(UserContext);

  const waiver_status = payload?.waiver_status;

  const hasBothPermissions = currentUserContext?.waiver_permissions?.can_request_waiver &&
    currentUserContext?.waiver_permissions?.can_respond_to_waiver;

  const hasNeitherPermission = !currentUserContext?.waiver_permissions?.can_request_waiver && !currentUserContext?.waiver_permissions?.can_respond_to_waiver;

  const canRequestWaiver = currentUserContext?.waiver_permissions?.can_request_waiver
  const canRespondToWaiver = currentUserContext?.waiver_permissions?.can_respond_to_waiver

  const requestButtonEnabled = canRequestWaiver && waiver_status === NOT_REQUESTED;
  // there is no "not requested" button but feel this boolean helps with the readability of the code as a "selected request button" is basically the not requested button
  const notRequestedButtonEnabled = canRequestWaiver && waiver_status === REQUESTED;

  const approvedButtonEnabled = canRespondToWaiver && (waiver_status === REQUESTED || waiver_status === DENIED);
  const deniedButtonEnabled = canRespondToWaiver && (waiver_status === REQUESTED || waiver_status === APPROVED);

  return (
    <>
      {hasNeitherPermission ? <></> : <div className="form-control">
        <label className="label">
          <span className="label-text">
            Waiver Status
          </span>
        </label>
        <div className="join join-horizontal">
          <button
            disabled={!requestButtonEnabled && !hasBothPermissions && !notRequestedButtonEnabled}
            className={`btn btn-primary ${payload?.waiver_status === "requested" || payload?.waiver_status === REQUESTED ? "btn-fill" : "btn-outline"} join-item w-1/3`}
            onClick={(event) => {
              event.preventDefault();
              if (payload?.waiver_status === "requested" || payload?.waiver_status === REQUESTED) {
                handleFieldChange("waiver_status", NOT_REQUESTED, formType);
              } else {
                handleFieldChange("waiver_status", REQUESTED, formType);
              }
            }}
          >
            Requested
          </button>
          <button
            disabled={!approvedButtonEnabled && !hasBothPermissions}
            className={`btn btn-primary ${payload?.waiver_status === "approved" || payload?.waiver_status === APPROVED ? "btn-fill" : "btn-outline"} join-item w-1/3`}
            onClick={(event) => {
              event.preventDefault();
              if (payload?.waiver_status === "approved" || payload?.waiver_status === APPROVED) {
                if (hasBothPermissions) {
                  handleFieldChange("waiver_status", NOT_REQUESTED, formType);
                } else {
                  return;
                }
              } else {
                handleFieldChange("waiver_status", APPROVED, formType);
              }
            }}
          >
            Approved
          </button>
          <button
            disabled={!deniedButtonEnabled && !hasBothPermissions}
            className={`btn btn-primary ${payload?.waiver_status === "denied" || payload?.waiver_status === DENIED ? "btn-fill" : "btn-outline"} join-item w-1/3`}
            onClick={(event) => {
              event.preventDefault();
              if (payload?.waiver_status === "denied" || payload?.waiver_status === DENIED) {
                if (hasBothPermissions) {
                  handleFieldChange("waiver_status", NOT_REQUESTED, formType);
                } else {
                  return;
                }
              } else {
                handleFieldChange("waiver_status", DENIED, formType);
              }
            }}
          >
            Denied
          </button>
        </div>
      </div>}
    </>
  );
}
