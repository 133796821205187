import { useState, useEffect, useContext } from "react";
import { useQueries } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrain,
  faArrowUpRight,
  faArrowRightFromArc,
  faArrowRightToArc,
} from "@fortawesome/pro-regular-svg-icons";
import LoadingSpinner from "../../../icons/LoadingSpinner";
import useFetchFunctions from "../../../services/trips";
import {
  defaultOccurrencePayload,
  defaultLinkedOccurrencePayload,
} from "./constants";
import { findFirstAndLastVehicles } from "../../../utils/helperFunctions";
import toast from "react-hot-toast";
import { formatFieldValues } from "../../../utils/formatters";
import { usePostTripOccurrence } from "../../../hooks/usePostTripOccurrence";
import { usePostResidualTripOccurrence } from "../../../hooks/usePostResidualTripOccurrence";
import { useEditTripOccurrence } from "../../../hooks/useEditTripOccurrence";
import { useEditResidualTripOccurrence } from "../../../hooks/useEditResidualTripOccurrence";
import {
  OperatorTripOccurrenceFieldsResponse,
  ResponsiblePartiesResponse,
  OperatorOccurrenceCausesResponse,
  TripOccurrencePayload,
  LinearAssetsResponse,
  StationaryAssetsResponse,
  FieldValue,
  DynamicFieldValue,
  ResidualTripOccurrencePayload,
} from "../../../types/forms";
import NewOccurrenceForm from "./form-tabs/NewOccurrenceForm";
import LinkToExistingForm from "./form-tabs/LinkToExistingForm";
import TripOccurrenceAuditLog from "../../manage-occurrences/TripOccurrenceAuditLog";
import { OccurrenceViaContext } from "../../../utils/Contexts";

const TripOccurrenceFlyoutHeader = ({ occurrenceVia }) => {
  if (occurrenceVia?.action === "originate") {
    return (
      <h2>
        <FontAwesomeIcon
          icon={faArrowRightFromArc}
          size="lg"
          className="text-primary"
        />{" "}
        Originate at {occurrenceVia?.trip_location?.location.name}
      </h2>
    );
  }
  if (occurrenceVia?.action === "terminate") {
    return (
      <h2>
        <FontAwesomeIcon icon={faArrowRightToArc} size="lg" className="text-error" />{" "}
        Terminate at {occurrenceVia?.trip_location?.location?.name}
      </h2>
    );
  }
  if (occurrenceVia?.action === "cancel") {
    return <h2>Cancel Trip</h2>;
  }
  return <h2>Delay Details</h2>;
};

const cleanOccurrenceDetails = (occurrence, cause) => {
  const occurrenceCopy = JSON.parse(JSON.stringify(occurrence));

  if (cause.linear_asset === "NOT SHOWN") {
    delete occurrenceCopy?.linear_asset_id;
    delete occurrenceCopy?.linear_asset_start;
    delete occurrenceCopy?.linear_asset_end;
  }

  if (cause.stationary_asset === "NOT SHOWN") {
    delete occurrenceCopy?.stationary_asset;
    delete occurrenceCopy?.stationary_asset_type;
  }

  if (cause.vehicle === "NOT SHOWN") {
    delete occurrenceCopy?.vehicle;
  }

  if (cause.location === "NOT SHOWN") {
    delete occurrenceCopy?.location;
  }

  return occurrenceCopy;
}

const staticValidationLinked = (payload, requiresDelayMinutes) => {
  const requiredFields = ["comments"];

  if (requiresDelayMinutes) {
    requiredFields.push("delay_minutes");
  }

  const allRequiredFieldsPresent = requiredFields.every((field) => {
    return payload[field] !== undefined && payload[field] !== "";
  });

  const eitherParentFieldPresent =
    (payload["parent_occurrence"] !== undefined && payload["parent_occurrence"] !== "") ||
    (payload["parent_trip_occurrence"] !== undefined && payload["parent_trip_occurrence"] !== "");

  return allRequiredFieldsPresent && eitherParentFieldPresent;
};

const staticValidation = (payload, requiresDelayMinutes) => {
  const requiredFields = ["operator_trip_occurrence_cause_id", "responsible_party_id", "comments"];

  if (requiresDelayMinutes) {
    requiredFields.push("delay_minutes");
  }

  const allRequiredFieldsPresent = requiredFields.every((field) => {
    return payload[field] !== undefined && payload[field] !== "" && payload[field] !== null;
  });

  return allRequiredFieldsPresent;
};


const interdependentCauseValidation = (payload, occurrenceCause) => {
  const linearAssetIsRequired = occurrenceCause?.linear_asset === "REQUIRED";
  const locationIsRequired = occurrenceCause?.location === "REQUIRED";
  const stationaryAssetIsRequired = occurrenceCause?.stationary_asset === "REQUIRED";
  const vehicleIsRequired = occurrenceCause?.vehicle === "REQUIRED";
  if (linearAssetIsRequired) {
    if (!payload["linear_asset_id"] || !payload["linear_asset_start"] || !payload["linear_asset_end"]) {
      return false;
    }
  }
  if (locationIsRequired) {
    if (!payload["location"]) {
      return false;
    }
  }
  if (stationaryAssetIsRequired) {
    if (!payload["stationary_asset"]) {
      return false;
    }
  }
  if (vehicleIsRequired) {
    if (!payload["vehicle"]) {
      return false;
    }
  }
  return true;
}

export default function TripOccurrenceFlyout({
  selectedOperatorVehicleCarrierCodes,
  operator_short_name,
  service_date,
  trip,
  occurrenceDrawer,
  setOccurrenceDrawer,
  occurrenceToBeEditedProp,
  setOccurrenceToBeEditedProp,
  isPrimary,
  fetchingForEdit,
  occurrenceVia,
  setOccurrenceVia,
  handleOriginateTripLocation,
  handleTerminateTripLocation,
  handleCancelTrip,
  forceShowLinearAssets,
}) {
  const { occurrenceViaContext, setOccurrenceViaContext } = useContext(OccurrenceViaContext);
  const isOriginateOrTerminate =
    occurrenceVia?.action === "originate" || occurrenceVia?.action === "terminate";
  const isCancel = occurrenceViaContext?.action === "cancel";
  const { firstVehicle, lastVehicle } = findFirstAndLastVehicles(
    trip?.assigned_vehicle_formation?.vehicle_formation_vehicles
  );

  //loading states
  const [postingOccurrence, setPostingOccurrence] = useState(false);
  const [searchingVehicle, setSearchingVehicle] = useState(false);

  //form states
  const [tripOccurrencePayload, setTripOccurrencePayload] = useState(
    defaultOccurrencePayload
  );
  console.log("🚀 ~ tripOccurrencePayload:", tripOccurrencePayload)
  const [linkedTripOccurrence, setLinkedTripOccurrence] = useState(
    defaultLinkedOccurrencePayload
  );
  const [
    operatorTripOccurrenceFieldsPayload,
    setOperatorTripOccurrenceFieldsPayload,
  ] = useState<DynamicFieldValue>({});
  const [occurrenceCauseValuesPayload, setOccurrenceCauseValuesPayload] = useState(
    {}
  );

  //edit mode form states
  const [originalValues, setOriginalValues] = useState({});
  const [originalOccurrenceCauseValues, setOriginalOccurrenceCauseValues] = useState(
    {}
  );
  const [
    originalOperatorTripOccurrenceFieldValues,
    setOriginalOperatorTripOccurrenceFieldValues,
  ] = useState({});
  //
  const [occurrenceToBeEdited, setOccurrenceToBeEdited] = useState({});
  const [linkedTripOccurrenceToBeEdited, setLinkedTripOccurrenceToBeEdited] =
    useState({});
  const [
    operatorTripOccurrenceFieldValuesForEdit,
    setOperatorTripOccurrenceFieldValuesForEdit,
  ] = useState({});
  const [occurrenceCauseValuesForEdit, setOccurrenceCauseValuesForEdit] = useState(
    {}
  );
  const [loadingEditState, setLoadingEditState] = useState(false);

  //other visual states
  const [tripOccurrenceType, setTripOccurrenceType] = useState("new");
  const [isEditMode, setIsEditMode] = useState(false);
  const [occurrenceCauseFields, setOccurrenceCauseFields] = useState([]);
  const [vehicleSearchPayload, setVehicleSearchPayload] = useState({
    carrier_code: "",
    serial_number: "",
  });

  const getIsButtonDisabled = () => {
    if (postingOccurrence) {
      return true;
    }

    if (tripOccurrenceType === "new") {
      return (
        !staticValidation(tripOccurrencePayload, isOriginateOrTerminate || isCancel) ||
        !interdependentCauseValidation(
          tripOccurrencePayload,
          tripOccurrencePayload?.operator_trip_occurrence_cause_id?.value
        )
      );
    }

    return (
      !staticValidationLinked(linkedTripOccurrence, isOriginateOrTerminate || isCancel)
    );
  };

  const resetFormState = (resetOccurrenceVia = true) => {
    setTripOccurrencePayload(defaultOccurrencePayload);
    setVehicleSearchPayload({ carrier_code: "", serial_number: "" });
    setOccurrenceCauseFields([]);
    setOccurrenceCauseValuesPayload({});
    setOperatorTripOccurrenceFieldsPayload({});
    setOperatorTripOccurrenceFieldValuesForEdit({});
    setOccurrenceCauseValuesForEdit({});
    setLinkedTripOccurrence(defaultLinkedOccurrencePayload);
    setInterdependentFields(defaultInterdependentFields);
    setIsEditMode(false);
    setLinkedTripOccurrenceToBeEdited({});
    setOccurrenceToBeEdited({});
    setInterdependentFieldsForEdit({});
    setLoadingEditState(false);
    if (occurrenceVia && resetOccurrenceVia) {
      setOccurrenceVia("");
    }
    if (occurrenceToBeEditedProp) {
      setOccurrenceToBeEditedProp(null);
    }
  };

  const resetOccurrenceCauseValues = () => {
    setOccurrenceCauseValuesPayload({});
    setOccurrenceCauseValuesForEdit({});
    setOriginalOccurrenceCauseValues({});
  }

  const {
    fetchOperatorTripOccurrenceFields,
    fetchResponsibleParties,
    fetchOperatorOccurrenceCauses,
    fetchLinearAssets,
    fetchStationaryAssets,
    fetchVehicle,
    postTripOccurrence,
    searchLinkToExisting,
    editTripOccurrence,
  } = useFetchFunctions();

  const occurrenceSearchPayload = {
    from_service_date: service_date,
    to_service_date: service_date,
    search_type: "link_to_existing",
  };

  const defaultInterdependentFields = {
    linear_asset: null,
    linear_asset_start: "",
    linear_asset_end: "",
    stationary_asset: null,
    equipment: null,
    location: null,
    occurrence_cause: null,
  };

  const resetInterdependentFields = () => {
    setInterdependentFields(defaultInterdependentFields);
    setInterdependentFieldsForEdit({});
  }

  const [interdependentFields, setInterdependentFields] = useState(defaultInterdependentFields);
  const [interdependentFieldsForEdit, setInterdependentFieldsForEdit] = useState({});

  const handleInterdependentFieldChange = (field, value) => {
    setInterdependentFields((prev) => ({
      ...prev,
      [field]: value,
    }));
    setInterdependentFieldsForEdit((prev) => ({
      ...prev,
      [field]: value,
    }));
  }

  const results = useQueries({
    queries: [
      {
        queryKey: ["operator_trip_occurrence_fields", operator_short_name],
        queryFn: () => fetchOperatorTripOccurrenceFields(operator_short_name),
        staleTime: 1000 * 10,
        enabled: occurrenceDrawer,
      },
      {
        queryKey: ["responsible_parties"],
        queryFn: () => fetchResponsibleParties(),
        staleTime: 1000 * 10,
        enabled: occurrenceDrawer,
      },
      {
        queryKey: ["operator_occurrence_causes", operator_short_name],
        queryFn: () => fetchOperatorOccurrenceCauses(operator_short_name),
        staleTime: 1000 * 10,
        enabled: occurrenceDrawer,
      },
      {
        queryKey: [
          "stationary_assets",
          operator_short_name,
          service_date,
          trip?.trip_name,
          tripOccurrencePayload?.operator_trip_occurrence_cause_id?.value
            ?.stationary_asset_type,
        ],
        queryFn: () =>
          fetchStationaryAssets(
            operator_short_name,
            service_date,
            trip?.trip_name,
            tripOccurrencePayload.operator_trip_occurrence_cause_id.value
              .stationary_asset_type
          ),
        staleTime: 0,
        enabled:
          !!tripOccurrencePayload?.operator_trip_occurrence_cause_id?.value
            ?.stationary_asset_type,
      },
      {
        queryKey: [
          "occurrences_operator_service_day",
          operator_short_name,
          service_date,
        ],
        queryFn: () => searchLinkToExisting(occurrenceSearchPayload),
        staleTime: 1000 * 10,
        enabled: tripOccurrenceType === "linked",
      },
      {
        queryKey: [
          "linear_assets",
          operator_short_name,
          service_date,
          trip?.trip_name,
        ],
        queryFn: () =>
          fetchLinearAssets(operator_short_name, service_date, trip?.trip_name),
        staleTime: 0,
        enabled: occurrenceDrawer && !fetchingForEdit,
      },
    ],
  });

  const { postTripOccurrenceHandler } = usePostTripOccurrence({
    operator_short_name,
    service_date,
    trip,
    setPostingOccurrence,
    resetFormState,
    setOccurrenceDrawer,
    setTripOccurrenceType,
    toast,
    postTripOccurrence,
    occurrenceVia,
  });

  const { postResidualTripOccurrenceHandler } = usePostResidualTripOccurrence({
    operator_short_name,
    service_date,
    trip,
    setPostingOccurrence,
    resetFormState,
    setOccurrenceDrawer,
    setTripOccurrenceType,
    toast,
    postResidualTripOccurrence: postTripOccurrence,
    occurrenceVia,
  });

  const { editTripOccurrenceHandler } = useEditTripOccurrence({
    operator_short_name,
    service_date,
    trip,
    setPostingOccurrence,
    resetFormState,
    setOccurrenceDrawer,
    setTripOccurrenceType,
    toast,
    editTripOccurrence,
    waiverQuickChange: false,
  });

  const { editResidualTripOccurrenceHandler } = useEditResidualTripOccurrence({
    operator_short_name,
    service_date,
    trip,
    setPostingOccurrence,
    resetFormState,
    setOccurrenceDrawer,
    setTripOccurrenceType,
    toast,
    editTripOccurrence,
  });

  const operatorTripOccurrenceFields: OperatorTripOccurrenceFieldsResponse =
    results[0];
  const responsibleParties: ResponsiblePartiesResponse = results[1];
  const operatorOccurrenceCauses: OperatorOccurrenceCausesResponse = results[2];
  const stationaryAssets: StationaryAssetsResponse = results[3];
  const occurrencesOperatorServiceDay = results[4];
  const linearAssets: LinearAssetsResponse = results[5];

  useEffect(() => {
    const anyLoading = results.some((result) => result.isLoading);
    setLoadingEditState(anyLoading);
  }, [results]);

  useEffect(() => {
    if (
      operatorTripOccurrenceFields.data &&
      !operatorTripOccurrenceFields.isLoading
    ) {
      const operatorOccurrencePayloadFormat = {};
      operatorTripOccurrenceFields.data.forEach((field) => {
        operatorOccurrencePayloadFormat[field.trip_occurrence_field.name] = "";
      });
      setOperatorTripOccurrenceFieldsPayload(operatorOccurrencePayloadFormat);
    }
  }, [operatorTripOccurrenceFields.data, operatorTripOccurrenceFields.isLoading]);

  const handleFieldChange = (field, value, formType) => {
    const originalValue = originalValues[field];

    const isValueChanged =
      typeof value === "boolean" ? value !== originalValue : value !== originalValue;

    if (field === "linear_asset_id") {
      setTripOccurrencePayload((payload) => ({
        ...payload,
        [field]: value,
        location: "", // Reset dependent fields
        stationary_asset: "",
        linear_asset_start: "",
        linear_asset_end: "",
      }));

      if (isEditMode) {
        setOccurrenceToBeEdited((payload) => {
          const updatedPayload = { ...payload, [field]: value };

          // Ensure dependent fields are reset
          if ("location" in payload) {
            delete updatedPayload.location;
          }
          if ("stationary_asset" in payload) {
            delete updatedPayload.stationary_asset;
          }
          return updatedPayload;
        });
      }
      return; // Exit early since we've handled this field
    }

    if (field === "location") {
      setTripOccurrencePayload((payload) => ({
        ...payload,
        [field]: value,
        stationary_asset: "",
      }));

      if (isEditMode) {
        setOccurrenceToBeEdited((payload) => {
          const updatedPayload = { ...payload, [field]: value };

          if ("stationary_asset" in payload) {
            delete updatedPayload.stationary_asset;
          }
          return updatedPayload;
        });
      }
      return;
    }

    if (field === "stationary_asset") {
      setTripOccurrencePayload((payload) => ({
        ...payload,
        [field]: value,
      }));

      if (isEditMode) {
        setOccurrenceToBeEdited((payload) => ({
          ...payload,
          [field]: value,
        }));
      }
      return;
    }

    if (field === "vehicle") {
      setTripOccurrencePayload((payload) => ({
        ...payload,
        [field]: value,
      }));

      if (isEditMode) {
        setOccurrenceToBeEdited((payload) => ({
          ...payload,
          [field]: value,
        }));
      }
      return;
    }

    if (formType === "linked") {
      setLinkedTripOccurrence((prevLinkedTripOccurrence) => ({
        ...prevLinkedTripOccurrence,
        [field]: value,
      }));

      if (isEditMode) {
        if (isValueChanged) {
          setLinkedTripOccurrenceToBeEdited(
            (prevLinkedTripOccurrenceToBeEdited) => ({
              ...prevLinkedTripOccurrenceToBeEdited,
              [field]: value,
            })
          );
        } else {
          setLinkedTripOccurrenceToBeEdited((prevLinkedTripOccurrenceToBeEdited) => {
            const updatedEdited = { ...prevLinkedTripOccurrenceToBeEdited };
            delete updatedEdited[field];
            return updatedEdited;
          });
        }
      }
    } else {
      setTripOccurrencePayload((prevOccurrencePayload) => ({
        ...prevOccurrencePayload,
        [field]: value,
      }));

      if (isEditMode) {
        if (isValueChanged) {
          setOccurrenceToBeEdited((prevOccurrenceToBeEdited) => ({
            ...prevOccurrenceToBeEdited,
            [field]: value,
          }));
        } else {
          setOccurrenceToBeEdited((prevOccurrenceToBeEdited) => {
            const updatedEdited = { ...prevOccurrenceToBeEdited };
            delete updatedEdited[field];
            return updatedEdited;
          });
        }
      }
    }
  };

  const handleValuesFieldChange = (
    field,
    value,
    setPayload,
    setPayloadForEdit,
    originalValues
  ) => {
    setPayload((prev) => ({
      ...prev,
      [field]: value,
    }));

    if (isEditMode) {
      if (JSON.stringify(value) !== JSON.stringify(originalValues[field])) {
        setPayloadForEdit((prev) => ({
          ...prev,
          [field]: value,
        }));
      } else {
        setPayloadForEdit((prev) => {
          const updatedEdited = { ...prev };
          delete updatedEdited[field];
          return updatedEdited;
        });
      }
    }
  };

  const handleSetOperatorTripOccurrenceFieldValues = (field, value) => {
    handleValuesFieldChange(
      field,
      value,
      setOperatorTripOccurrenceFieldsPayload,
      setOperatorTripOccurrenceFieldValuesForEdit,
      originalOperatorTripOccurrenceFieldValues
    );
  };

  const handleSetOccurrenceCauseValues = (field, value) => {
    handleValuesFieldChange(
      field,
      value,
      setOccurrenceCauseValuesPayload,
      setOccurrenceCauseValuesForEdit,
      originalOccurrenceCauseValues
    );
  };

  const handleVehicleLookUp = async () => {
    setSearchingVehicle(true);

    try {
      await fetchVehicle(
        operator_short_name,
        vehicleSearchPayload.carrier_code?.value,
        vehicleSearchPayload.serial_number
      ).then((res) => {
        if (!res.error) {
          if (isEditMode) {
            setOccurrenceToBeEdited((prevOccurrenceToBeEdited) => ({
              ...prevOccurrenceToBeEdited,
              vehicle: res,
            }));
            setTripOccurrencePayload((prevOccurrencePayload) => ({
              ...prevOccurrencePayload,
              vehicle: res,
            }));
          } else {
            setTripOccurrencePayload((prevOccurrencePayload) => ({
              ...prevOccurrencePayload,
              vehicle: res,
            }));
          }
        }
        setSearchingVehicle(false);
      });
    } catch (error) {
      console.log("Error:", error);
      setSearchingVehicle(false);
    }
  };

  const handlePostTripOccurrenceWrapper = async () => {
    const occurrenceJson = JSON.parse(JSON.stringify(tripOccurrencePayload));
    const interdependentFieldsJson = JSON.parse(JSON.stringify(interdependentFields));

    const formattedOperatorTripOccurrenceFieldsPayload: FieldValue[] =
      formatFieldValues(operatorTripOccurrenceFieldsPayload);

    const formattedOccurrenceCauseValuesPayload: FieldValue[] = formatFieldValues(
      occurrenceCauseValuesPayload
    );

    const cleanedOccurrence = cleanOccurrenceDetails(
      occurrenceJson,
      occurrenceJson?.operator_trip_occurrence_cause_id?.value
    );

    const formattedPayload: TripOccurrencePayload = {
      occurrence: {
        primary: cleanedOccurrence?.primary,
        field_report: false,
        linear_asset_id: interdependentFieldsJson?.linear_asset?.id || null,
        linear_asset_start: interdependentFieldsJson?.linear_asset_start != null
          ? Number(interdependentFieldsJson.linear_asset_start)
          : null,
        linear_asset_end: interdependentFieldsJson?.linear_asset_end != null
          ? Number(interdependentFieldsJson.linear_asset_end)
          : null,
        occurrence_cause_id:
          cleanedOccurrence?.operator_trip_occurrence_cause_id?.value?.id,
        location_id: interdependentFieldsJson?.location?.value,
        responsible_party_id: cleanedOccurrence?.responsible_party_id?.value,
        stationary_asset_id: interdependentFieldsJson?.stationary_asset?.value,
        vehicle_id: cleanedOccurrence?.vehicle?.id,
        values: formattedOccurrenceCauseValuesPayload,
      },
      trip_occurrence: {
        delay_minutes: cleanedOccurrence?.delay_minutes,
        values: formattedOperatorTripOccurrenceFieldsPayload,
        trip_status_attribution: false,
        waiver_status: cleanedOccurrence?.waiver_status || "Not Requested",
        comments: cleanedOccurrence?.comments,
      },
    };

    await postTripOccurrenceHandler(formattedPayload);
  };

  const handlePostResidualTripOccurrenceWrapper = async () => {
    const occurrenceJson = JSON.parse(JSON.stringify(linkedTripOccurrence));

    const formattedOperatorTripOccurrenceFieldsPayload: FieldValue[] =
      formatFieldValues(operatorTripOccurrenceFieldsPayload);

    const formattedPayload: ResidualTripOccurrencePayload = {
      occurrence: {},
      trip_occurrence: {
        delay_minutes: occurrenceJson?.delay_minutes,
        comments: occurrenceJson?.comments,
        waiver_status: occurrenceJson?.waiver_status || "Not Requested",
        parent_trip_occurrence_id: null,
        trip_status_attribution: false,
        values: formattedOperatorTripOccurrenceFieldsPayload,
      },
    };

    if (
      occurrenceJson?.parent_occurrence &&
      occurrenceJson.parent_occurrence !== ""
    ) {
      formattedPayload.trip_occurrence.occurrence_id =
        occurrenceJson?.parent_occurrence?.occurrence_id;
    }

    if (
      occurrenceJson?.parent_trip_occurrence &&
      occurrenceJson.parent_trip_occurrence !== ""
    ) {
      formattedPayload.trip_occurrence.parent_trip_occurrence_id =
        occurrenceJson?.parent_trip_occurrence?.value;
    }

    await postResidualTripOccurrenceHandler(formattedPayload);
  };

  useEffect(() => {
    if (
      occurrenceToBeEditedProp &&
      !loadingEditState &&
      Object.keys(occurrenceToBeEdited).length === 0 &&
      Object.keys(linkedTripOccurrenceToBeEdited).length === 0
    ) {
      setIsEditMode(true);
      setOccurrenceDrawer(true);
      const dataToBeEditedJson = JSON.parse(
        JSON.stringify(occurrenceToBeEditedProp)
      );

      const {
        delay_minutes,
        comments,
        waiver_status,
        occurrence,
        trip_status_attribution,
        trip: trip_id,
        trip_occurrence_values,
      } = dataToBeEditedJson;

      const formattedOperatorTripOccurrenceFieldsPayload = {};

      if (trip_occurrence_values) {
        trip_occurrence_values.forEach((value) => {
          formattedOperatorTripOccurrenceFieldsPayload[
            value?.operator_trip_occurrence_field?.trip_occurrence_field?.name
          ] = value.value;
        });
      }

      setOperatorTripOccurrenceFieldsPayload(
        formattedOperatorTripOccurrenceFieldsPayload
      );
      setOriginalOperatorTripOccurrenceFieldValues(
        formattedOperatorTripOccurrenceFieldsPayload
      );

      const formattedOccurrence = {
        delay_minutes: delay_minutes,
        waiver_status: waiver_status,
        comments: comments,

        //not sure why these ones are here but leaving for now
        // parent_occurrence: occurrence,
        trip_status_attribution: trip_status_attribution,
        reported_only: false, // Assuming default value
        trip_id: trip_id,
      };

      if (dataToBeEditedJson?.primary || isPrimary) {
        setTripOccurrenceType("new");

        const location = occurrence?.location || "";
        const stationary_asset = occurrence?.stationary_asset || "";
        const vehicle = occurrence?.vehicle || "";
        const responsible_party_id = occurrence?.responsible_party || "";
        const occurrence_cause = occurrence?.occurrence_cause || "";
        const linear_asset_id = occurrence?.linear_asset || "";
        const linear_asset_start = occurrence?.linear_asset_start || "";
        const linear_asset_end = occurrence?.linear_asset_end || "";
        const primary = occurrence?.primary;

        let currentCause = { value: "", label: "" };
        if (Array.isArray(operatorOccurrenceCauses?.data)) {
          currentCause = operatorOccurrenceCauses.data.find(
            (cause) => cause.occurrence_cause.name === occurrence_cause
          );
        }

        let currentLocation = { value: "", label: "" };
        if (Array.isArray(trip?.trip_locations)) {
          currentLocation = trip.trip_locations.find(
            (location_lookup) => location_lookup.location.code === location.code
          );

          if (currentLocation) {
            formattedOccurrence["location"] = {
              value: currentLocation.location.id,
              label: currentLocation.location.name,
            };
          }
        } else {
          formattedOccurrence["location"] = {
            value: location.id,
            label: location.name,
          };
        }

        if (currentCause) {
          formattedOccurrence["operator_trip_occurrence_cause_id"] = {
            value: currentCause?.occurrence_cause,
            label: currentCause?.occurrence_cause?.name,
          };
        }

        formattedOccurrence["primary"] = primary;
        formattedOccurrence["vehicle"] = vehicle;
        formattedOccurrence["responsible_party_id"] = {
          value: responsible_party_id,
          label: responsible_party_id,
        };
        formattedOccurrence["stationary_asset"] = {
          value: stationary_asset,
          label: stationary_asset,
        };
        formattedOccurrence["linear_asset_id"] = {
          value: linear_asset_id,
          label: linear_asset_id,
        };
        formattedOccurrence["linear_asset_start"] = linear_asset_start;
        formattedOccurrence["linear_asset_end"] = linear_asset_end;

        const formattedOccurrenceCauseValuesPayload = {};

        if (occurrence?.occurrence_cause_values) {
          occurrence?.occurrence_cause_values?.forEach((value) => {
            if (value?.occurrence_cause_field?.lookup_list_items?.length > 0) {
              formattedOccurrenceCauseValuesPayload[
                value?.occurrence_cause_field?.name
              ] = {
                value: value?.value,
                label: value?.value,
              };
            } else {
              formattedOccurrenceCauseValuesPayload[
                value?.occurrence_cause_field?.name
              ] = value?.value;
            }
          });
        }

        const formattedInterdependentFields = defaultInterdependentFields;
        if (stationary_asset) {
          formattedInterdependentFields["stationary_asset"] = {
            value: stationary_asset,
            label: stationary_asset,
            location: stationary_asset,
            name: stationary_asset
          };
        }
        if (linear_asset_id) {
          formattedInterdependentFields["linear_asset"] = {
            value: linear_asset_id,
            label: linear_asset_id,
            name: linear_asset_id,
          };
          formattedInterdependentFields["linear_asset_start"] = linear_asset_start;
          formattedInterdependentFields["linear_asset_end"] = linear_asset_end;
        }
        if (location) {
          formattedInterdependentFields["location"] = {
            value: location?.id,
            label: location?.name,
            id: location?.id,
          };
        }

        setInterdependentFields(formattedInterdependentFields);

        setOriginalValues(formattedOccurrence);
        setOccurrenceCauseFields(currentCause?.occurrence_cause_fields);
        setOccurrenceCauseValuesPayload(formattedOccurrenceCauseValuesPayload);
        setOriginalOccurrenceCauseValues(formattedOccurrenceCauseValuesPayload);
        setTripOccurrencePayload(formattedOccurrence);
      } else {
        setTripOccurrenceType("linked");

        if (dataToBeEditedJson.parent_trip_occurrence) {
          formattedOccurrence["parent_trip_occurrence"] =
            dataToBeEditedJson.parent_trip_occurrence;
        } else {
          formattedOccurrence["parent_occurrence"] = occurrence;
        }

        setOriginalValues(formattedOccurrence);
        setLinkedTripOccurrence(formattedOccurrence);
      }
    }
  }, [occurrenceToBeEditedProp, loadingEditState]);

  const handleEditTripOcccurrenceWrapper = async () => {
    const formattedOperatorTripOccurrenceFieldsPayload: FieldValue[] =
      formatFieldValues(operatorTripOccurrenceFieldValuesForEdit);
    let formattedOccurrenceCauseValuesPayload: FieldValue[] = formatFieldValues(
      occurrenceCauseValuesPayload
    );

    const interdependentFieldsJson = JSON.parse(JSON.stringify(interdependentFields));
    const interdependentFieldsForEditJson = JSON.parse(JSON.stringify(interdependentFieldsForEdit));

    const hasCauseFields = Object.keys(occurrenceCauseFields).length > 0;

    const hasOccurrenceCauseValues =
      originalValues?.occurrence_cause_values?.length > 0;

    if (!hasCauseFields && hasOccurrenceCauseValues) {
      formattedOccurrenceCauseValuesPayload = [];
    }
    const editedOccurrenceJson = JSON.parse(JSON.stringify(occurrenceToBeEdited));

    const initialPayload = {
      occurrence: {
        occurrence_id: occurrenceToBeEditedProp?.occurrence?.id,
        primary: editedOccurrenceJson?.primary,
        responsible_party_id: editedOccurrenceJson?.responsible_party_id?.value,
        occurrence_cause_id:
          editedOccurrenceJson?.operator_trip_occurrence_cause_id?.value?.id,
        vehicle_id: editedOccurrenceJson?.vehicle === "" || editedOccurrenceJson?.vehicle === null ? null : editedOccurrenceJson?.vehicle?.id,
        values: formattedOccurrenceCauseValuesPayload,
      },
      trip_occurrence: {
        occurrence_id: occurrenceToBeEditedProp?.occurrence?.id,
        trip_occurrence_id: occurrenceToBeEditedProp?.id,
        comments: editedOccurrenceJson?.comments,
        delay_minutes: editedOccurrenceJson?.delay_minutes,
        waiver_status: editedOccurrenceJson?.waiver_status,
        values: formattedOperatorTripOccurrenceFieldsPayload,
      },
    };

    if ("location" in interdependentFieldsForEditJson) {
      if (interdependentFieldsForEditJson?.location === null || interdependentFieldsForEditJson?.location === "") {
        initialPayload.occurrence.location_id = null;
      } else {
        initialPayload.occurrence.location_id = interdependentFieldsForEditJson?.location?.value;
      }
    }
    if ("stationary_asset" in interdependentFieldsForEditJson) {
      if (interdependentFieldsForEditJson?.stationary_asset === null || interdependentFieldsForEditJson?.stationary_asset === "") {
        initialPayload.occurrence.stationary_asset_id = null;
      } else {
        initialPayload.occurrence.stationary_asset_id = interdependentFieldsForEditJson?.stationary_asset?.value;
      }
    }
    if ("linear_asset" in interdependentFieldsForEditJson) {
      if (interdependentFieldsForEditJson?.linear_asset === null || interdependentFieldsForEditJson?.linear_asset === "") {
        initialPayload.occurrence.linear_asset_id = null;
        initialPayload.occurrence.linear_asset_start = null;
        initialPayload.occurrence.linear_asset_end = null;
      } else {
        initialPayload.occurrence.linear_asset_id = interdependentFieldsForEditJson?.linear_asset?.value?.id;
        initialPayload.occurrence.linear_asset_start = interdependentFieldsForEditJson?.linear_asset_start;
        initialPayload.occurrence.linear_asset_end = interdependentFieldsForEditJson?.linear_asset_end;
      }
    }

    const cleanedOccurrence = cleanOccurrenceDetails(
      initialPayload.occurrence,
      tripOccurrencePayload?.operator_trip_occurrence_cause_id?.value
    );

    const formattedPayload: TripOccurrencePayload = {
      occurrence: cleanedOccurrence,
      trip_occurrence: initialPayload.trip_occurrence,
    };

    if (tripOccurrencePayload?.trip_status_attribution) {
      formattedPayload.trip_occurrence.delay_minutes = null;
    }

    if ("primary" in editedOccurrenceJson) {
      formattedPayload.occurrence.primary = editedOccurrenceJson.primary;
    }

    if (editedOccurrenceJson.responsible_party_id) {
      formattedPayload.occurrence.responsible_party_id =
        editedOccurrenceJson.responsible_party_id.value;
    }

    if (editedOccurrenceJson.operator_trip_occurrence_cause_id) {
      formattedPayload.occurrence.occurrence_cause_id =
        editedOccurrenceJson.operator_trip_occurrence_cause_id.value.id;
    }

    if (formattedOccurrenceCauseValuesPayload.length > 0) {
      formattedPayload.occurrence.values = formattedOccurrenceCauseValuesPayload;
    }

    if (formattedOperatorTripOccurrenceFieldsPayload.length > 0) {
      formattedPayload.trip_occurrence.values =
        formattedOperatorTripOccurrenceFieldsPayload;
    }

    const editTripOccurrenceHandlerResponse = await editTripOccurrenceHandler(formattedPayload);
    console.log("🚀 ~ handleEditTripOcccurrenceWrapper ~ editTripOccurrenceHandlerResponse:", editTripOccurrenceHandlerResponse)
  };

  const handleEditResidualTripOccurrenceWrapper = async () => {
    const formattedOperatorTripOccurrenceFieldsPayload: FieldValue[] =
      formatFieldValues(operatorTripOccurrenceFieldValuesForEdit);

    const editedLinkedTripOccurrenceJson = JSON.parse(
      JSON.stringify(linkedTripOccurrenceToBeEdited)
    );

    const formattedPayload = {
      occurrence: {},
      trip_occurrence: {
        occurrence_id: occurrenceToBeEditedProp?.occurrence?.id,
        trip_occurrence_id: occurrenceToBeEditedProp?.id,
        values: formattedOperatorTripOccurrenceFieldsPayload,
        waiver_status: editedLinkedTripOccurrenceJson?.waiver_status,
        comments: editedLinkedTripOccurrenceJson?.comments,
        delay_minutes: editedLinkedTripOccurrenceJson?.delay_minutes,
      },
    };

    if (
      editedLinkedTripOccurrenceJson?.parent_occurrence &&
      editedLinkedTripOccurrenceJson.parent_occurrence !== ""
    ) {
      formattedPayload.trip_occurrence.occurrence_id =
        editedLinkedTripOccurrenceJson?.parent_occurrence?.occurrence_id;
    }

    if (
      editedLinkedTripOccurrenceJson?.parent_trip_occurrence &&
      editedLinkedTripOccurrenceJson.parent_trip_occurrence !== ""
    ) {
      formattedPayload.trip_occurrence.parent_trip_occurrence_id =
        editedLinkedTripOccurrenceJson?.parent_trip_occurrence?.value;
    }

    await editResidualTripOccurrenceHandler(formattedPayload);
  };

  return (
    <div className="drawer-side z-30">
      <label htmlFor="add-occurrence-drawer" className="drawer-overlay"></label>
      <div className="min-h-full w-full md:w-1/2 xl:w-1/3 bg-base-100 p-5 text-base-content prose">
        <TripOccurrenceFlyoutHeader occurrenceVia={occurrenceVia} />
        <button
          onClick={() => {
            resetFormState();
            setOccurrenceDrawer(false);
            setTripOccurrenceType("new");
          }}
          className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
        >
          ✕
        </button>

        {fetchingForEdit ? (
          <>
            <div className="w-full h-screen skeleton"></div>
          </>
        ) : (
          <>
            <div className="card card-compact mb-7 p-3 border border-info bg-[#F2ECF7]">
              <div className="flex gap-10">
                <div className="flex gap-5">
                  <div>
                    <div>
                      <FontAwesomeIcon icon={faTrain} /> Trip
                    </div>
                    <div
                      className={`text-[22px] ${occurrenceToBeEditedProp ? "text-primary cursor-pointer hover:underline" : ""} font-bold`}
                      onClick={() => {
                        occurrenceToBeEditedProp &&
                          window.open(
                            `/trips/operator/${operator_short_name}/operator_service_day/${service_date}/trip/${trip?.trip_name}`,
                            '_blank'
                          );
                      }}
                    >
                      {trip?.trip_name}
                      {occurrenceToBeEditedProp && (
                        <FontAwesomeIcon className="cursor-pointer hover:underline" icon={faArrowUpRight} />
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="text-[#808080]">Date</div>
                    <div className="text-[22px] font-bold">{service_date}</div>
                  </div>
                </div>

                <div>
                  <div className="text-[#808080]">Vehicle Formation</div>
                  {firstVehicle?.vehicle && lastVehicle?.vehicle ? (
                    <div className="flex gap-1 items-center">
                      <div className="badge badge-md badge-outline">
                        {" "}
                        {firstVehicle?.vehicle?.serial_number}
                      </div>
                      -
                      <div className="badge badge-md badge-outline">
                        {" "}
                        {lastVehicle?.vehicle?.serial_number}
                      </div>
                    </div>
                  ) : (
                    <div className="lg:text-3xl">No Formation</div>
                  )}
                </div>
              </div>
            </div>

            {isEditMode && (<TripOccurrenceAuditLog trip_occurrence_id={occurrenceToBeEditedProp?.id} operator_short_name={operator_short_name} service_date={service_date} trip_name={trip.trip_name} />)}

            {!isEditMode && (
              <div className="join join-horizontal w-full mb-6">
                <button
                  className={`btn btn-primary join-item w-1/2 ${tripOccurrenceType === "new" ? "btn-success" : "btn-outline"}`}
                  onClick={() => {
                    resetFormState(false);
                    setTripOccurrenceType("new");
                  }}
                >
                  New
                </button>
                <button
                  className={`btn btn-primary join-item w-1/2 ${tripOccurrenceType === "linked" ? "btn-success" : "btn-outline"}`}
                  onClick={() => {
                    resetFormState(false);
                    setTripOccurrenceType("linked");
                  }}
                >
                  Link to Existing
                </button>
              </div>
            )}

            {tripOccurrenceType === "new" && (
              <NewOccurrenceForm
                //form type props
                occurrenceVia={occurrenceVia}
                occurrenceDrawer={occurrenceDrawer}
                forceShowLinearAssets={forceShowLinearAssets}
                //data props
                trip={trip}
                service_date={service_date}
                linearAssets={linearAssets}
                stationaryAssets={stationaryAssets}
                responsibleParties={responsibleParties}
                selectedOperatorVehicleCarrierCodes={selectedOperatorVehicleCarrierCodes}
                operator_short_name={operator_short_name}
                operatorOccurrenceCauses={operatorOccurrenceCauses}
                //form field generators
                occurrenceCauseFields={occurrenceCauseFields}
                setOccurrenceCauseFields={setOccurrenceCauseFields}
                operatorTripOccurrenceFields={operatorTripOccurrenceFields}
                //form states
                tripOccurrencePayload={tripOccurrencePayload}
                occurrenceCauseValuesPayload={occurrenceCauseValuesPayload}
                operatorTripOccurrenceFieldsPayload={
                  operatorTripOccurrenceFieldsPayload
                }
                //form setters
                handleFieldChange={handleFieldChange}
                handleSetOperatorTripOccurrenceFieldValues={
                  handleSetOperatorTripOccurrenceFieldValues
                }
                setTripOccurrencePayload={setTripOccurrencePayload}
                handleSetOccurrenceCauseValues={handleSetOccurrenceCauseValues}
                resetOccurrenceCauseValues={resetOccurrenceCauseValues}
                //vehicle search
                searchingVehicle={searchingVehicle}
                handleVehicleLookUp={handleVehicleLookUp}
                vehicleSearchPayload={vehicleSearchPayload}
                setVehicleSearchPayload={setVehicleSearchPayload}
                //for edit mode
                isEditMode={isEditMode}
                originalValues={originalValues}
                loadingEditState={loadingEditState}
                //new
                interdependentFields={interdependentFields}
                handleInterdependentFieldChange={handleInterdependentFieldChange}
                resetInterdependentFields={resetInterdependentFields}
              />
            )}

            {tripOccurrenceType === "linked" && (
              <LinkToExistingForm
                //form type props
                occurrenceVia={occurrenceVia}
                //data props
                operatorTripOccurrenceFields={operatorTripOccurrenceFields}
                occurrencesOperatorServiceDay={occurrencesOperatorServiceDay}
                //form states
                linkedTripOccurrence={linkedTripOccurrence}
                operatorTripOccurrenceFieldsPayload={
                  operatorTripOccurrenceFieldsPayload
                }
                //form setters
                handleFieldChange={handleFieldChange}
                setOperatorTripOccurrenceFieldsPayload={
                  setOperatorTripOccurrenceFieldsPayload
                }
                handleSetOperatorTripOccurrenceFieldValues={
                  handleSetOperatorTripOccurrenceFieldValues
                }
                setLinkedTripOccurrence={setLinkedTripOccurrence}
                //for edit mode
                isEditMode={isEditMode}
                originalValues={originalValues}
              />
            )}

            <hr className="my-3" />

            <div className="flex gap-2 mt-5">
              <button
                disabled={postingOccurrence}
                onClick={() => {
                  resetFormState();
                  setOccurrenceDrawer(false);
                  setTripOccurrenceType("new");
                }}
                className="btn btn-primary btn-outline"
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                disabled={getIsButtonDisabled()}
                onClick={(event) => {
                  event.preventDefault();
                  if (tripOccurrenceType === "new") {
                    if (isEditMode) {
                      handleEditTripOcccurrenceWrapper();
                    } else {
                      handlePostTripOccurrenceWrapper();
                    }
                  } else if (tripOccurrenceType === "linked") {
                    if (isEditMode) {
                      handleEditResidualTripOccurrenceWrapper();
                    } else {
                      handlePostResidualTripOccurrenceWrapper();
                    }
                  }
                  if (occurrenceVia?.action === "originate") {
                    handleOriginateTripLocation(occurrenceVia?.trip_location?.id);
                  }
                  if (occurrenceVia?.action === "terminate") {
                    handleTerminateTripLocation(occurrenceVia?.trip_location?.id);
                  }
                  if (occurrenceVia?.action === "cancel") {
                    handleCancelTrip();
                  }
                }}
              >
                Save
                {postingOccurrence && <LoadingSpinner />}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
