import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRight } from "@fortawesome/pro-solid-svg-icons";
import { formatInTimeZone } from "date-fns-tz";
import useFetchFunctions from "../../services/trips";
import LoadingSpinner from "../../icons/LoadingSpinner";
import { AuditLogEntry } from "../../types/auditlog";

const TimeStamp = ({ timestamp }) => (
    <td>{formatInTimeZone(timestamp, "America/New_York", "MM/dd/yy - h:mm a")}</td>
);

const ChangeRow = ({
    contentType,
    objectRepr,
    actor,
    fromValue,
    toValue,
    timestamp,
}) => (
    <tr>
        <td>
            {"<"}
            {contentType}
            {"> "}
            <span className="font-bold">{objectRepr}</span>
            {" changed from "}
            <span className="font-bold">{fromValue}</span>
            {" to "}
            <span className="font-bold">{toValue}</span>
        </td>
        <td>{actor || "System"}</td>
        <TimeStamp timestamp={timestamp} />
    </tr>
);


interface TripOccurrenceAuditLogProps {
    operator_short_name: string;
    trip_occurrence_id: number;
    service_date: string;
    trip_name: string;
}

export default function TripOccurrenceAuditLog({ operator_short_name, trip_occurrence_id, service_date, trip_name }: TripOccurrenceAuditLogProps) {
    const [auditLogDrawerIsOpen, setAuditLogDrawerIsOpen] = useState(false);

    const { fetchTripOccurrenceAuditLog } = useFetchFunctions();

    const { isLoading, isError, data, error } = useQuery({
        queryKey: ["trip_occurrence_audit_log", operator_short_name, service_date, trip_name, trip_occurrence_id],
        queryFn: () => fetchTripOccurrenceAuditLog(operator_short_name, trip_occurrence_id),
        staleTime: 1000 * 10,
    });

    return (
        <>
            {!isError && !error && (
                <div className="drawer drawer-end">
                    <input
                        id="trip-occurrence-audit-log-drawer" type="checkbox"
                        className="drawer-toggle"
                        checked={auditLogDrawerIsOpen}
                        onChange={() => { }}
                    />
                    <div className="mb-7 flex justify-between">
                        <div className="flex gap-2">
                            <div className="badge badge-md badge-error">
                                {isLoading ? <LoadingSpinner /> : (data?.length || 0)}
                            </div>
                            logged updates
                        </div>
                        <button disabled={isLoading} className="btn btn-sm btn-ghost text-primary p-2" onClick={() => setAuditLogDrawerIsOpen(true)}>
                            <span className="hidden sm:inline">View Audit Log</span>
                            <FontAwesomeIcon icon={faArrowUpRight} />
                        </button>
                    </div>

                    <div className="drawer-side z-30">
                        <label htmlFor="trip-occurrence-audit-log-drawer" className="drawer-overlay"></label>
                        <div className="min-h-full min-w-full md:w-1/2 xl:w-1/3 bg-base-100 p-5 text-base-content prose">
                            <h2>Audit Log</h2>
                            <label
                                onClick={() => setAuditLogDrawerIsOpen(false)}
                                className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                            >
                                ✕
                            </label>

                            <table className="table m-0 lg:text-base">
                                <tbody>
                                    {data?.length &&
                                        data
                                            .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                                            .map((entry: AuditLogEntry, i: number) => {
                                                const {
                                                    serialized_data,
                                                    action,
                                                    content_type,
                                                    object_repr,
                                                    changes,
                                                    changes_text,
                                                    actor,
                                                    timestamp,
                                                } = entry;

                                                if (
                                                    content_type === "tripoccurrencevalue"
                                                ) {
                                                    const values = changes["value"] || [];
                                                    return (
                                                        <ChangeRow
                                                            key={i}
                                                            contentType={content_type}
                                                            objectRepr={object_repr}
                                                            actor={actor}
                                                            fromValue={values[0] || ""}
                                                            toValue={values[1] || ""}
                                                            timestamp={timestamp}
                                                        />
                                                    );
                                                }

                                                if (
                                                    action === "update" && content_type === "tripoccurrence"
                                                ) {
                                                    if (!changes && !changes_text) return null;
                                                    if (!changes && changes_text) {
                                                        return (
                                                            <tr key={i}>
                                                                <td>
                                                                    <span>{changes_text}</span>
                                                                </td>
                                                                <td>{actor}</td>
                                                                <TimeStamp timestamp={timestamp} />
                                                            </tr>
                                                        );
                                                    }

                                                    for (const [key, values] of Object.entries(changes)) {
                                                        const objectRep = `${object_repr}.${key}`;
                                                        return (
                                                            <ChangeRow
                                                                key={i}
                                                                contentType={content_type}
                                                                objectRepr={objectRep}
                                                                actor={actor}
                                                                fromValue={values[0] || ""}
                                                                toValue={values[1] || ""}
                                                                timestamp={timestamp}
                                                            />
                                                        );
                                                    }
                                                }

                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            {"<"}
                                                            {content_type}
                                                            {"> "} {action}
                                                            {changes_text && ": " + changes_text}
                                                        </td>
                                                        <td>{actor || "System"}</td>
                                                        <TimeStamp timestamp={timestamp} />
                                                    </tr>
                                                );
                                            })}
                                </tbody>
                            </table>

                            <div className="flex gap-2">
                                <button
                                    onClick={() => {
                                        setAuditLogDrawerIsOpen(false);
                                    }}
                                    className="btn btn-primary btn-outline"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>)}
        </>
    )
}