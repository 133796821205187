import SingleSelect from "../../SingleSelect";

export default function LinearAssetsField({
    isDisabled,
    linearAssets,
    interdependentFields,
    handleOnChange,
}) {
    return (
        <div className="form-control">
            <div className="flex gap-4 items-center">
                <div className="hidden w-32 sm:block">
                    <label className="font-bold">
                        {" "}
                        <span className="label-text">
                            Linear Asset
                        </span>
                    </label>
                </div>
                <div className="w-full sm:w-60">
                    <label className="label font-bold sm:hidden">
                        <span className="label-text">
                            Linear Asset
                        </span>
                    </label>
                    <SingleSelect
                        isDisabled={linearAssets.isLoading || isDisabled}
                        isLoading={linearAssets.isLoading}
                        placeholder="Pick one"
                        value={interdependentFields.linear_asset}
                        handleOnChange={(linear_asset) => handleOnChange(linear_asset)}
                        options={
                            Array.isArray(linearAssets.data) &&
                            linearAssets?.data?.map((linearAsset) => {
                                return {
                                    label: linearAsset.name,
                                    value: linearAsset,
                                };
                            })
                        }
                    />
                </div>
            </div>
        </div>
    )
}
