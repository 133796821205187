import LinearAssetsField from "./LinearAssetField";
import LocationsField from "./LocationField";
import StationaryAssetsField from "./StationaryAssetField";

export default function InterdependentOccurrenceSearchFormFields({
    locations,
    linearAssets,
    selectedOperator,
    stationaryAssets,
    interdependentFields,
    handleInterdependentFieldChange,
}) {
    //enabled/disabled state of fields
    const locationFieldDisabled = !selectedOperator || !interdependentFields.linear_asset;
    const linearAssetFieldDisabled = !selectedOperator;
    const stationaryAssetFieldDisabled = !selectedOperator || !interdependentFields.linear_asset || !interdependentFields.stationary_asset_type;
    const stationaryAssetTypeFieldDisabled = !selectedOperator || !interdependentFields.linear_asset;

    //selected values
    const selectedLocation = interdependentFields.location;
    const selectedLinearAsset = interdependentFields.linear_asset;
    const selectedStationaryAsset = interdependentFields.stationary_asset;

    const stationaryAssetTypes = selectedLinearAsset?.value?.stationary_asset_types ?? [];

    //simple filtering on two dependent fields
    const filteredLocations = locations?.data?.filter((location) => {
        if (locations.isLoading) {
            return [];
        }
        if (selectedStationaryAsset) {
            return location.id === selectedStationaryAsset.location;
        } else {
            return location;
        }
    });
    const filteredStationaryAssets = stationaryAssets?.data?.filter((stationaryAsset) => {
        if (stationaryAssets.isLoading) {
            return [];
        }
        if (selectedLocation) {
            return stationaryAsset.id === selectedLocation.value;
        } else {
            return stationaryAsset;
        }
    });

    const handleLinearAssetsFieldChange = (linear_asset) => {
        if (!linear_asset) {
            handleInterdependentFieldChange("stationary_asset", null);
            handleInterdependentFieldChange("stationary_asset_type", null);
            handleInterdependentFieldChange("stationary_asset_location", null);
        }
        handleInterdependentFieldChange("linear_asset", linear_asset);
    }

    const handleLocationFieldChange = (location) => handleInterdependentFieldChange("location", location);

    return (
        <>
            <LinearAssetsField
                isDisabled={linearAssetFieldDisabled}
                linearAssets={linearAssets}
                interdependentFields={interdependentFields}
                handleOnChange={handleLinearAssetsFieldChange}
            />
            <LocationsField
                isDisabled={locationFieldDisabled}
                locations={filteredLocations}
                interdependentFields={interdependentFields}
                handleOnChange={handleLocationFieldChange}
            />
            <StationaryAssetsField
                isDisabled={stationaryAssetFieldDisabled}
                stationaryAssetTypeFieldDisabled={stationaryAssetTypeFieldDisabled}
                stationaryAssets={filteredStationaryAssets}
                interdependentFields={interdependentFields}
                handleInterdependentFieldChange={handleInterdependentFieldChange}
                stationaryAssetTypes={stationaryAssetTypes}
            />
        </>
    )
}
