export const defineDynamicColumns = (
  editMode: boolean,
  trip_locations: [],
  operatorTripLocationFields: [],
  visibleTableColumns: []
) => {
  const dynamicColumns: {
    name: string;
    display_name: string;
    visible: boolean;
  }[] = [];

  if (editMode === true && operatorTripLocationFields?.length > 0) {
    for (const operatorTripLocationField of operatorTripLocationFields) {
      const fieldName = operatorTripLocationField?.trip_location_field?.name;
      const hasAtLeastOneValue = trip_locations.some((trip_location) =>
        trip_location.trip_location_values.some(
          (trip_location_value) =>
            trip_location_value.operator_trip_location_field ===
            operatorTripLocationField.trip_location_field.id && trip_location_value
        )
      );

      if (!hasAtLeastOneValue && !operatorTripLocationField?.can_edit) {
        continue;
      }

      if (!dynamicColumns.some((col) => col.name === fieldName) && fieldName) {
        dynamicColumns.push({
          name: fieldName,
          display_name: operatorTripLocationField?.trip_location_field?.label ?? "",
          visible: true,
          can_edit: operatorTripLocationField?.can_edit ?? false,
          hasAtLeastOneValue: hasAtLeastOneValue,
        });
      }
    }
  } else if (editMode === false) {
    for (const location of trip_locations || []) {
      for (const value of location?.trip_location_values || []) {
        const fieldName = value?.trip_location_field?.name;
        if (
          !dynamicColumns.some((col) => col.name === fieldName) &&
          value?.value !== "" &&
          fieldName
        ) {
          dynamicColumns.push({
            name: fieldName,
            display_name: value?.trip_location_field?.label ?? "",
            visible: true,
          });
        }
      }
    }
  }

  return dynamicColumns;
};
