import LinearAssetsField from "./LinearAssetField";
import LocationsField from "./LocationField";
import StationaryAssetsField from "./StationaryAssetField";
import { useMemo } from "react";

export default function InterdependentDelayFormFields({
    tripLocations,
    locationsByLinearAsset,
    linearAssets,
    selectedOperator,
    stationaryAssets,
    interdependentFields,
    handleInterdependentFieldChange,
    occurrenceCause,
}) {
    const locations = !tripLocations ? locationsByLinearAsset?.map((location) => location) : tripLocations?.map((location) => location?.location);
    const linearAssetIsRequired = occurrenceCause?.linear_asset === "REQUIRED";
    const locationIsRequired = occurrenceCause?.location === "REQUIRED";
    const stationaryAssetIsRequired = occurrenceCause?.stationary_asset === "REQUIRED";
    const linearAssetIsOptional = occurrenceCause?.linear_asset === "OPTIONAL";
    const locationIsOptional = occurrenceCause?.location === "OPTIONAL";
    const stationaryAssetIsOptional = occurrenceCause?.stationary_asset === "OPTIONAL";
    const showLinearAssetFields = linearAssetIsRequired || linearAssetIsOptional;
    const showLocationFields = locationIsRequired || locationIsOptional;
    const showStationaryAssetFields = stationaryAssetIsRequired || stationaryAssetIsOptional;

    const locationFieldDisabled = !selectedOperator || !occurrenceCause;
    const linearAssetFieldDisabled = !selectedOperator || !occurrenceCause;
    const stationaryAssetFieldDisabled = !selectedOperator || !occurrenceCause;

    // Memoize initial values
    const memoizedInitialValues = useMemo(() => {
        const foundLinearAsset = linearAssets?.data?.find((linear_asset) =>
            linear_asset.name === interdependentFields?.linear_asset?.name
        );
        const foundLocation = locations?.find((location) =>
            location?.id === interdependentFields?.location?.id
        );
        const foundStationaryAsset = stationaryAssets?.data?.find((stationaryAsset) => {
            // Match by location_id
            const matchesLocationId = stationaryAsset.location_id === foundLocation?.id;
            // Match by asset name
            const matchesAssetName = stationaryAsset.assets?.some(
                (asset) => asset.name === interdependentFields?.stationary_asset?.name
            );
            return matchesLocationId || matchesAssetName;
        });

        return {
            linear_asset: foundLinearAsset || interdependentFields.linear_asset,
            location: foundLocation || interdependentFields.location,
            stationary_asset: foundStationaryAsset || interdependentFields.stationary_asset,
        };
    }, [linearAssets, locations, stationaryAssets, interdependentFields]);

    const filteredStationaryAssets = stationaryAssets?.data?.filter((stationaryAsset) => {
        if (stationaryAssets.isLoading) {
            return false;
        }
        if (memoizedInitialValues.linear_asset && memoizedInitialValues.location) {
            return (
                stationaryAsset?.linear_asset_ids?.includes(memoizedInitialValues.linear_asset.id) &&
                stationaryAsset?.location_id === memoizedInitialValues.location?.id
            );
        }
        if (memoizedInitialValues.linear_asset) {
            return stationaryAsset?.linear_asset_ids?.includes(memoizedInitialValues.linear_asset?.id);
        }
        if (!memoizedInitialValues.location) {
            return true;
        }
        if (memoizedInitialValues.location) {
            return stationaryAsset?.location_id === memoizedInitialValues.location?.id;
        }
        return true;
    });

    const filteredLinearAssets = linearAssets?.data?.filter((linearAsset) => {
        if (linearAssets?.isLoading) {
            return false;
        }
        if (!memoizedInitialValues.stationary_asset) {
            return true;
        }
        if (memoizedInitialValues.stationary_asset) {
            return memoizedInitialValues.stationary_asset?.linear_asset_ids?.includes(linearAsset?.id);
        }
        return true;
    });

    const filteredLocations = locations?.filter((location) => {
        if (locations?.isLoading) {
            return false;
        }
        if (memoizedInitialValues.linear_asset && memoizedInitialValues.stationary_asset) {
            return memoizedInitialValues.stationary_asset?.location_id === location?.id;
        }
        if (memoizedInitialValues.linear_asset) {
            return filteredStationaryAssets?.some((asset) => asset?.location_id === location?.id);
        }
        if (!memoizedInitialValues.stationary_asset) {
            return true;
        }
        if (memoizedInitialValues.stationary_asset) {
            return location?.id === memoizedInitialValues.stationary_asset?.location_id;
        }
        return true;
    });

    const handleLinearAssetsFieldChange = (linear_asset) => {
        if (!linear_asset) {
            handleInterdependentFieldChange("stationary_asset", null);
            handleInterdependentFieldChange("location", null);
            handleInterdependentFieldChange("linear_asset", null);
            handleInterdependentFieldChange("linear_asset_start", "");
            handleInterdependentFieldChange("linear_asset_end", "");
        } else {
            handleInterdependentFieldChange("linear_asset", linear_asset);
            handleInterdependentFieldChange("linear_asset_start", linear_asset?.value?.start_measurement);
            handleInterdependentFieldChange("linear_asset_end", linear_asset?.value?.end_measurement);
        }
    };

    const handleLinearAssetStartFieldChange = (start) => handleInterdependentFieldChange("linear_asset_start", start);
    const handleLinearAssetEndFieldChange = (end) => handleInterdependentFieldChange("linear_asset_end", end);
    const handleLocationFieldChange = (location) => handleInterdependentFieldChange("location", location);

    return (
        <>
            {(showLinearAssetFields || !tripLocations) && (
                <LinearAssetsField
                    isDisabled={linearAssetFieldDisabled}
                    isRequired={linearAssetIsRequired}
                    linearAssets={filteredLinearAssets}
                    interdependentFields={interdependentFields}
                    handleOnChange={handleLinearAssetsFieldChange}
                    handleStartChange={handleLinearAssetStartFieldChange}
                    handleEndChange={handleLinearAssetEndFieldChange}
                    showStartAndEnd={linearAssetIsRequired || occurrenceCause?.linear_asset === "OPTIONAL"}
                />
            )}
            {showLocationFields && (
                <LocationsField
                    isDisabled={locationFieldDisabled}
                    isRequired={locationIsRequired}
                    locations={filteredLocations}
                    interdependentFields={interdependentFields}
                    handleOnChange={handleLocationFieldChange}
                />
            )}
            {showStationaryAssetFields && (
                <StationaryAssetsField
                    isDisabled={stationaryAssetFieldDisabled}
                    isRequired={stationaryAssetIsRequired}
                    stationaryAssets={filteredStationaryAssets}
                    interdependentFields={interdependentFields}
                    handleInterdependentFieldChange={handleInterdependentFieldChange}
                />
            )}
        </>
    );
}
