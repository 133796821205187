import { useQueryClient } from "@tanstack/react-query";
import { TripOccurrencePayloadEditMode } from "../types/forms";

const convertDelayToTableFormat = (editedDelayResponse, originalDelay) => {
    const trip_occurrence = editedDelayResponse;
    const occurrence = editedDelayResponse?.occurrence;

    const prioritizeLocationDisplay = () => {
        if (occurrence?.stationary_asset) {
            return occurrence?.stationary_asset;
        } else if (occurrence?.location) {
            return occurrence?.location?.name;
        } else if (occurrence?.linear_asset) {
            return `${occurrence?.linear_asset} (${occurrence?.linear_asset_start} - ${occurrence?.linear_asset_end})`;
        } else return "Unknown Location";
    };

    const locationDisplay = prioritizeLocationDisplay();

    const carrier_code = occurrence?.vehicle?.carrier_code;
    const comments = trip_occurrence?.comments;
    const delay_minutes = trip_occurrence?.delay_minutes;
    const location = locationDisplay;
    const occurrence_name = occurrence?.occurrence_cause;
    const occurrence_type = occurrence?.occurrence_cause_type;
    const occurrence_primary = occurrence?.primary;
    const responsible_party_name = occurrence?.responsible_party;
    const serial_number = occurrence?.vehicle?.serial_number;
    const trip_occurrence_id = trip_occurrence?.id;
    const waiver_status = trip_occurrence?.waiver_status;

    const formattedDelay = {
        carrier_code: carrier_code,
        comments: comments,
        delay_minutes: delay_minutes,
        location: location,
        occurrence_name: occurrence_name,
        occurrence_primary: occurrence_primary,
        occurrence_type: occurrence_type,
        responsible_party_name: responsible_party_name,
        serial_number: serial_number,
        service_date: originalDelay?.service_date,
        trip_name: originalDelay?.trip_name,
        trip_occurrence_id: originalDelay?.trip_occurrence_id,
        trip_occurrence_primary: originalDelay?.trip_occurrence_primary,
        trip_values: originalDelay?.trip_values,
        waiver_status: waiver_status,
        id: trip_occurrence_id,
    };

    return formattedDelay;
};

interface UseEditTripOccurrenceParams {
    operator_short_name: string;
    service_date: string;
    trip: any;
    setPostingOccurrence: (posting: boolean) => void;
    resetFormState: () => void;
    setOccurrenceDrawer: (open: boolean) => void;
    setTripOccurrenceType: (type: string) => void;
    toast: any;
    waiverQuickChange: boolean | undefined;
    editTripOccurrence: (
        operator_short_name: string,
        service_date: string,
        trip_name: string,
        payload: any
    ) => Promise<any>;
}

export const useEditTripOccurrence = ({
    operator_short_name,
    service_date,
    trip,
    setPostingOccurrence,
    resetFormState,
    setOccurrenceDrawer,
    setTripOccurrenceType,
    toast,
    editTripOccurrence,
    waiverQuickChange,
}: UseEditTripOccurrenceParams) => {
    const queryClient = useQueryClient();

    const editTripOccurrenceHandler = async (
        tripOccurrencePayload: TripOccurrencePayloadEditMode
    ) => {
        const cleanObject = (obj: any) => {
            return Object.fromEntries(
                Object.entries(obj).filter(([key, value]) => {
                    // Allow "vehicle" to have an empty string value
                    if (key === "vehicle_id") return true;
                    if (key === "stationary_asset_id") return true;
                    if (key === "linear_asset_start") return true;
                    if (key === "linear_asset_end") return true;
                    if (key === "linear_asset_id") return true;
                    if (key === "location_id") return true;

                    return value !== "" && value !== null && value !== undefined;
                })
            );
        };

        const cleanedPayload = {
            occurrence: cleanObject(tripOccurrencePayload.occurrence),
            trip_occurrence: cleanObject(tripOccurrencePayload.trip_occurrence),
        };

        setPostingOccurrence(true);
        try {
            await editTripOccurrence(
                operator_short_name,
                service_date,
                trip.trip_name,
                cleanedPayload
            ).then((res) => {
                if (!res.error) {
                    resetFormState();
                    setTripOccurrenceType("new");
                    setOccurrenceDrawer(false);
                    if (waiverQuickChange) {
                        toast.success("Delay waiver status updated");
                    } else {
                        toast.success(`Trip ${trip?.trip_name} saved`);
                    }
                    queryClient.setQueryData(
                        ["trip", operator_short_name, service_date, trip.trip_name],
                        (oldData) => {
                            const updatedTripOccurrences =
                                oldData?.trip_occurrences?.map((trip_occurrence) => {
                                    if (trip_occurrence.id === res.id) {
                                        return res;
                                    }
                                    return trip_occurrence;
                                });
                            return {
                                ...oldData,
                                trip_occurrences: updatedTripOccurrences,
                            };
                        }
                    );
                    queryClient
                        .getQueryCache()
                        .findAll()
                        .forEach((query) => {
                            const queryKey = query.queryKey;

                            if (queryKey[0] === "occurrenceSearchResults") {
                                queryClient.setQueryData(queryKey, (oldData) => {
                                    if (!oldData) return oldData; // Skip if no data in the cache

                                    // Update the results within the matching query
                                    const updatedTripOccurrences =
                                        oldData?.results?.map((trip_occurrence) => {
                                            if (
                                                trip_occurrence?.trip_occurrence_id ===
                                                res.id
                                            ) {
                                                return convertDelayToTableFormat(
                                                    res,
                                                    trip_occurrence
                                                );
                                            }
                                            return trip_occurrence;
                                        });

                                    return {
                                        ...oldData,
                                        results: updatedTripOccurrences,
                                    };
                                });
                            }
                        });
                }
                setPostingOccurrence(false);
            });
        } catch (error) {
            console.log(error);
            setPostingOccurrence(false);
        }
    };

    return { editTripOccurrenceHandler };
};
