import SingleSelect from "../../SingleSelect";

export default function StationaryAssetsField({
    isDisabled,
    stationaryAssets,
    interdependentFields,
    handleInterdependentFieldChange,
    isRequired
}) {
    const sortedStationaryAssets = Array.isArray(stationaryAssets)
        ? stationaryAssets.sort((a, b) => {
            if (a.sequence === b.sequence) {
                return a.subsequence - b.subsequence;
            }
            return a.sequence - b.sequence;
        })
        : [];
    return (
        <div className="form-control">
            <label className="label">
                <span className="label-text">Stationary Asset</span>
                {isRequired && <span className="text-error">*</span>}
            </label>
            <SingleSelect
                isDisabled={stationaryAssets?.isLoading || isDisabled}
                isLoading={stationaryAssets?.isLoading}
                placeholder="Pick one"
                value={interdependentFields.stationary_asset}
                handleOnChange={(stationary_asset) => handleInterdependentFieldChange("stationary_asset", stationary_asset)}
                useFormatGroupLabel={true}
                options={sortedStationaryAssets.map((stationaryAsset) => {
                    const options = stationaryAsset?.assets?.map((asset) => {
                        return {
                            value: asset.id,
                            label: asset.name,
                            location: asset?.location,
                            location_id: stationaryAsset?.location_id,
                            linear_asset_ids: stationaryAsset?.linear_asset_ids
                        };
                    });
                    return {
                        label: stationaryAsset.location_name,
                        options: options,
                    };
                })}
            />

        </div>
    );
}